
.aluxnavidad2020 { 
    /* background: rgb(0,0,0); */
    /* background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(75,75,75,1) 20%); */
    font-family: 'Montserrat' !important;    
}

.aluxnavidad2020 h1 {
    font-family: 'Montserrat';
    color: white !important;
}

.aluxnavidad2020 .main_image {
    width: 100%;
    margin-bottom: 50px;
}

.aluxnavidad2020 .show-description {
    font-family: 'Montserrat';
}

.aluxnavidad2020 .promo {
    padding: 20px 0px;
    margin: 20px 0px;
    display: flex;
    justify-content: center;
    background: black;
}

.aluxnavidad2020 .gallery {
    padding: 20px 0px;
    margin: 20px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: black;

}

.aluxnavidad2020 .gallery .pic {
    width: 640px;
    margin-bottom: 20px;
}

.aluxnavidad2020 .gallery .pic img {
    width: 640px;
}


.aluxnavidad2020 .show-header {
    background: #de2c2d url('https://assets.viveloonline.com/shows/5fd97dc4efef01b70368a538/alux-player-headerbg.png') repeat-x;
    color: white;
    border-bottom: solid 3px white;
    padding: 0px 20px 0px 0px;
  }
  .aluxnavidad2020 .show-header .tema {
    width: 540px;
    height: 80px;
  }
  .aluxnavidad2020 .show-header .tema img {
    width: 100%;
    height: 100%;
  }
  .aluxnavidad2020 .show-header .tema img.desktop {
    display: inline-block;
  }
  .aluxnavidad2020 .show-header .tema img.mobile {
    display: none;
  }
  
  .aluxnavidad2020 .brand-footer {
    background: #de2c2d url('https://assets.viveloonline.com/shows/5fd97dc4efef01b70368a538/alux-player-background.png') no-repeat;  
    background-size: 100%;
    background-position: center;
    height: 250px;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

  }

  .aluxnavidad2020 .brand-footer img {
      width: 440px;
  }

@media (max-width: 900px) {
    .aluxnavidad2020 .biobox {
        display: block;
    }
    .aluxnavidad2020 .gallery .pic {
        width: 100%;
        margin-bottom: 20px;
    }
    
    .aluxnavidad2020 .gallery .pic img {
        width: 100%;
    }

    .aluxnavidad2020 .show-header {
        background-size: cover;
      }
    .aluxnavidad2020 .show-header .tema {
        width: 236px;
        height: 35px;    
      }
    .aluxnavidad2020 .brand-footer {
        flex-direction: column;
        height: auto;
        background-size: cover;
    }
    

    .aluxnavidad2020 .brand-footer img {
        width: 360px;
    }
}