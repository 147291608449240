
.site-despuesdelsilencio { 
  color: white;
  font-family: 'Montserrat' !important;
  padding-left: 0px;
  padding-right: 0px;
  /* margin-top: 105px !important; */
  background-color: black;    
}


.site-despuesdelsilencio .body {
  width: 100%;
  margin-top: 60px;
}

.site-despuesdelsilencio .body .cover {
  width: 100%;
}
.site-despuesdelsilencio .body .cover img {
  width: 100%;
}

.site-despuesdelsilencio .body .content {
  padding: 10px 0px;
}
.site-despuesdelsilencio .body .content .artist {
  font-weight: bold;
  font-size: 20px;
  border-bottom: solid 1px white;
  margin-bottom: 20px;
  padding-left: 10px;
}
.site-despuesdelsilencio .body .content .album {

}
.site-despuesdelsilencio .body .content .album-art {
  width: 100%;
  height: 250px;
  background: url('https://assets.viveloonline.com/sites/armandopineda/Despues del Silencio/album-art.png');
  background-size: cover;
  background-repeat: no-repeat;
}
.site-despuesdelsilencio .body .content .album-art img {
  width: 100%;
  height: 100%;
}

.site-despuesdelsilencio .body .content .playlist {
  margin-top: -30px;
  padding-left: 20px;
  padding-right: 20px;
}
.site-despuesdelsilencio .body .content .playlist .album-name {
  font-weight: bold;
  font-size: 20px;
}
.site-despuesdelsilencio .body .content .playlist .subtitle {
  font-size: 14px;
  font-style: italic;
}
.site-despuesdelsilencio .body .content .playlist .artist-name {
  font-size: 10px;
  margin-bottom: 25px;
}

.site-despuesdelsilencio .body .content .playlist .song {
  display: grid;
  grid-template-columns: 50px auto 50px;
  column-gap: 5px;    
  margin-bottom: 10px;  
}

.site-despuesdelsilencio .body .content .playlist .song .song-name {
  display: flex;
  justify-content: left;
  font-size: 14px;
}
.site-despuesdelsilencio .body .content .playlist .song .song-duration {
  display: flex;
  justify-content: right;
  font-size: 12px;
  color: #eee;
  padding-right: 5px;
}
.site-despuesdelsilencio .body .content .playlist .song .download {
  display: flex;
  justify-content: center;  
}
.site-despuesdelsilencio .body .content .playlist .song .download svg {
  color: #4cc7d1;
}
.site-despuesdelsilencio .body .content .album-download {
  padding-top: 20px;
  font-weight: bold;
  /* padding-left: 15px;   */
}
.site-despuesdelsilencio .body .content .album-download a {
  text-decoration: none;
}

@media (min-width: 900px) {
  .site-despuesdelsilencio .body {
    display: grid;
    grid-template-columns: 600px auto;
  }

  .site-despuesdelsilencio .body .content .album {
    display: grid;
    grid-template-columns: 300px auto;
    column-gap: 30px;
  }

  .site-despuesdelsilencio .body .content .album-art {
    width: 300px;
    height: 300px;
    border: solid 1px white;
  }
  .site-despuesdelsilencio .body .content .playlist {
    margin-top: 0px;
    max-width: 500px;
  }
  .site-despuesdelsilencio .body .content .playlist .song:hover {
    background: #333;
  }
}