
.site-malasintenciones { 
    color: white;
    font-family: 'Montserrat' !important;
    padding-left: 0px;
    padding-right: 0px;
    /* margin-top: 105px !important; */
    background-color: black;    
}

.site-malasintenciones .header .background {
  width: 100%;
  height: 56.25vw;  
  background-position: center center;
  background-size: cover;  
  /* background-image: url("http://assets.viveloonline.com/shows/5eed57a46ba924af375b7178/juevesdetrova-20200626-portada.png"); */
  z-index: -1;
  /* position: absolute; */
  top:0;
  left:0;
}

.site-malasintenciones .show-info {
  margin-top: -200px;
  padding: 0px 100px;
}

.site-malasintenciones .show-info .show-title {
  font-weight: bold
}

.site-malasintenciones .show-info .show-badges {
  font-size: 10px;
  display: flex;
  justify-content: flex-start;
  line-height: 21px;  
}
.site-malasintenciones .show-info .show-year {
  margin-right: 10px;
  line-height: 21px;
  height: 21px;
}
.site-malasintenciones .show-info .show-rating {
  margin-right: 10px;
  padding: 4px 4px 0px 6px;
  border: solid 1px #4B4B4B;
  line-height: 12px;
  height: 21px;
}
.site-malasintenciones .show-info .show-quality {
  margin-right: 10px;
  padding: 4px 4px 0px 6px;
  border: solid 1px #4B4B4B;
  line-height: 12px;
  height: 21px;
}
.site-malasintenciones .show-info .show-description {
  margin-top: 30px;
  width: 70%;
}

.site-malasintenciones .body {
  padding: 20px 100px;  
}

.site-malasintenciones .body .seasons {
  display: flex;
  flex-direction: row;
  justify-content: start;
  border-bottom: solid 1px cb177f;
  font-size: 1.5rem;
}
.site-malasintenciones .body .seasons .season-title {
  font-weight: bold;
  padding-left: 15px;
  padding-right: 50px;
  padding-bottom: 5px;
}
.site-malasintenciones .body .seasons .season-name {
  font-weight: bold;
  padding-bottom: 5px;
}
.site-malasintenciones .body .seasons .season-name a {
  text-decoration: none; 
  display: block; 
  padding-left: 50px;
  padding-right: 50px;
  cursor: pointer;
}
.site-malasintenciones .body .seasons .season-name.selected {
  color: #cb177f;  
  border-bottom: solid 5px #cb177f;
}
.site-malasintenciones .body .seasons .season-name.selected a {
  color: #cb177f; 
}

.site-malasintenciones .show-playlist {
  padding: 10px 0px 10px 0px;
  background: black;  
  /* background: #191919; */
  border-top: solid 1px #333333;
}
.site-malasintenciones .show-playlist .show-item {
  /* margin: 20px; */
  padding: 30px 0px 30px 0px;
  display: grid;
  grid-template-columns: 320px auto;
  grid-template-rows: 1.5rem auto;
  border-bottom: solid 1px #333333;
}
.site-malasintenciones .show-playlist .show-item.active {
  background: #333333;
}
.site-malasintenciones .show-playlist .show-item .show-item-image {
  grid-row: 1 / span 2;
  width: 300px;
  height: 170px;
  margin-right: 20px;
  position: relative;
  /* margin-bottom: 20px; */
}
.site-malasintenciones .show-playlist .show-item .show-item-image img {
  width: 300px;
  height: 170px;
}
.site-malasintenciones .show-playlist .show-item .show-item-image .play-overlay {
  width: 100%;
  height: 100%;
  color: white;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-content: center;
}
.site-malasintenciones .show-playlist .show-item .show-item-image .play-overlay a {
  width: 100%;
  height: 100%;
  font-size: 5rem;
  color: rgb(255,255,255,0);
  background: rgb(0,0,0,0);
  cursor: pointer;
}
.site-malasintenciones .show-playlist .show-item .show-item-image .play-overlay a:hover {
  width: 100%;
  height: 100%;  
  /* color: rgb(0,0,0,0.7);
  background: rgb(255,255,255,0.4); */
  color: rgb(255,255,255,1);
  background: rgb(0,0,0,0.7);
}
.site-malasintenciones .show-playlist .show-item .show-item-image .live-overlay {
  /*width: 100%;
  height: 100%;*/
  color: white;
  position: absolute;
  top: 0px;
  right: 0px;
  text-align: right;  
}
.site-malasintenciones .show-playlist .show-item .show-item-image .live-overlay div {
  z-index: 101;
  font-size: 0.8rem;
  margin-right: 10px;
  margin-top: 6px;  
  padding: 2px 5px;
  border-radius: 3px;
  background: rgb(0,0,0,0.5);
}
.site-malasintenciones .show-playlist .show-item .show-item-image .soon-overlay {
  width: 100%;
  height: 100%;
  color: white;
  font-weight: bold;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(0,0,0,0.6);  
}
.site-malasintenciones .show-playlist .show-item .show-item-content {
  padding-left: 20px;
}
.site-malasintenciones .show-playlist .show-item .show-item-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.site-malasintenciones .show-playlist .show-item .show-item-title {
  font-weight: bold;
  margin-bottom: 5px;
}
.site-malasintenciones .show-playlist .show-item .show-item-title a {
  text-decoration: none;
}
.site-malasintenciones .show-playlist .show-item .show-item-title a:hover {
  text-decoration: underline;
  cursor: pointer;
}
.site-malasintenciones .show-playlist .show-item .show-item-date {
  font-style: italic;
  font-size: 0.9rem;
}
.site-malasintenciones .show-playlist .show-item .show-item-description {

}
.site-malasintenciones .show-playlist .show-item .show-item-action {
  margin-top: 15px;
  font-size: 0.8rem;
}
.site-malasintenciones .show-playlist .show-item .show-item-action button,
.site-malasintenciones .show-playlist .show-item .show-item-action a {
  width: 200px;
}

.site-malasintenciones .footer {
  background: #414141;
  padding: 30px;
  margin: 50px 100px;    
}
.site-malasintenciones .footer h2 {
  font-size: 1.3rem;
}
.site-malasintenciones .footer .content {
  display: grid;
  grid-template-columns: auto 200px;
  text-align: justify;
}
.site-malasintenciones .footer .content .logos {
  padding-left: 20px;
}
.site-malasintenciones .footer .logos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
.site-malasintenciones .footer .logos img {
  /* height: 150px; */
}


@media (max-width: 768px) {

  .site-malasintenciones {
    margin-top: 50px !important;
  }
  .site-malasintenciones .header .background {
    height: 431px;
  }
  .site-malasintenciones .show-info {
    margin-top: -10px;
    padding: 0px 20px;
  }
  .site-malasintenciones .show-info .show-description {
    margin-top: 30px;
    width: 100%;
  }
  .site-malasintenciones .body {
    padding: 10px 20px;
  }

  .site-malasintenciones .body .seasons {
    display: flex;
    flex-direction: row;
    justify-content: start;
    border-bottom: solid 1px cb177f;
    font-size: 1.1rem;
    margin-top: 20px;
  }
  .site-malasintenciones .body .seasons .season-title {
    font-weight: bold;
    padding-left: 5px;
    padding-right: 20px;
    padding-bottom: 5px;
  }
  .site-malasintenciones .body .seasons .season-name {
    font-weight: bold;
    padding-bottom: 5px;
  }
  .site-malasintenciones .body .seasons .season-name a {
    text-decoration: none; 
    display: block; 
    padding-left: 30px;
    padding-right: 30px;
    cursor: pointer;
  }

  .site-malasintenciones .show-playlist {
    padding: 20px 0px 20px 0px; 
  }
  .site-malasintenciones .show-playlist .show-item {    
    padding: 20px 0px;    
    grid-template-columns: 160px auto;
    grid-template-rows: auto;
  }
  .site-malasintenciones .show-playlist .show-item.active {
    background: #333333;
  }
  .site-malasintenciones .show-playlist .show-item .show-item-image {
    grid-row: 1 / 1;
    width: 150px;
    height: 85px;
    margin-right: 10px;    
    position: relative;
    /* margin-bottom: 20px; */
  }
  .site-malasintenciones .show-playlist .show-item .show-item-image img {
    width: 150px;
    height: 85px;
  }
  .site-malasintenciones .show-playlist .show-item .show-item-image .play-overlay a {
    font-size: 2.5rem;
    color: rgb(255,255,255,1);
    background: rgb(0,0,0,0.4);
  }
  .site-malasintenciones .show-playlist .show-item .show-item-image .live-overlay div {
    margin-right: 0px;
    margin-top: 0px;  
  }
  .site-malasintenciones .show-playlist .show-item .show-item-info {
    display: block;
  }
  .site-malasintenciones .show-playlist .show-item .show-item-description {
    grid-column: 1 / span 2;
    padding-top: 10px;
  }
  .site-malasintenciones .footer {
    padding: 30px;
    margin: 0px 20px;
  }
  .site-malasintenciones .footer .content {
    display: inline-block;
    margin: 0px;    
  }
  .site-malasintenciones .footer .content .logos {
    padding: 0px;
  }


}

