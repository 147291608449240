
.beatles2021 { 
    color: white;       
    font-family: 'Montserrat' !important;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 25px !important;
    background-color: #EB4900;    
}


/* PLAYER */

.beatles2021.player {
    background: #EB4900 !important;
    background-image: none;
    margin-top: 0px !important;
}
.beatles2021 .show-header {
    display: block;
    padding: 0;
    background: #EB4900;
    color: white;        
    background: #EB4900 url('https://assets.viveloonline.com/shows/608f26b10a8a890f8c8ca88d/BeatlesFondo.png') no-repeat 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;    
    padding: 10px 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }   
  .beatles2021 .show-header .tema {
    width: 140px;    
    /* height: 80px; */
  }
  .beatles2021 .show-header .tema img {
    width: 100%;
    height: 100%;
  }
  .beatles2021 .show-header .tema img.desktop {
    display: inline-block;    
  }
  .beatles2021 .show-header .tema img.mobile {
    display: none;
  }
  .beatles2021 .video-player {
      background-image: url('https://assets.viveloonline.com/shows/608f26b10a8a890f8c8ca88d/BeatlesBN.png');
  }

  .beatles2021 .show-footer {
    background-color: #EB4900;    
    background: #EB4900 url('https://assets.viveloonline.com/shows/608f26b10a8a890f8c8ca88d/BeatlesFondo.png') no-repeat;    
    /* background: url('https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/sparks.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%; */
    background-size: cover;
    
  }
  .beatles2021 .show-footer .sponsors {
    display: grid;
    grid-template-columns: 220px 220px 220px 220px;
    align-items: center;
    /* grid-template-rows: 200px; */
    width: 880px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
    padding-bottom: 50px;
}
.beatles2021 .show-footer .sponsors img {
    width: 175px;
}  
.beatles2021 .show-footer .collage {
    text-align: center;
    padding-bottom: 100px;
}
.beatles2021 .show-footer .collage img {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}  

.beatles2021 .contact-box {
    background: #cb0f7e;
    color: white;
    padding: 20px;
    text-align: center;
}
.beatles2021 .contact-box img {
    width: 150px;
    padding-bottom: 10px;
}

.beatles2021 .help-box {
    padding: 30px;
    background: black;
}

  
  @media (max-width: 992px) {
    .beatles2021 .show-header {
        height: 45px;        
        padding: 5px;
        padding-right: 10px;
        /* padding-top: 10px; */
        /* position: fixed; */
        top:0px;
        left:0px;
        right:0px;
    }
    .beatles2021 .show-header .tema {
      width: 62px;
      height: 35px;    
    }
    .beatles2021 .show-header .tema img.desktop {
      display: none;
    }
    .beatles2021 .show-header .tema img.mobile {
      display: inline-block;
    }
    .beatles2021 .show-player {
        /* position: fixed;
        top: 45px;
        z-index: 9999; */
    }
    .beatles2021 .show-footer {
       /* margin-top: 280px; */
    }
    .beatles2021 .show-footer .sponsors {
      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-rows: 120px;
      width: 100%;
      align-items: center;      
      margin-left: auto;
      margin-right: auto;
    }
    .beatles2021 .show-footer .sponsors img {
        width: 120px;
        justify-self: center;
    } 
    .beatles2021 .show-interactions {
        /* margin-top: 260px; */
    }
    .beatles2021 .brand-footer {
      
    }
    .beatles2021 .brand-footer img {
        width: 100%;
    }
    .beatles2021 .brand-footer img.desktop {
        display: none;
      }
      .beatles2021 .brand-footer img.mobile {
        display: inline-block;
      }
    .beatles2021 .small-logo {
      width: 56px !important;
      height: 20px !important;
      margin-top: -4px;
    }
    .beatles2021 .show-header .social-icons {
        position: fixed;
        bottom: 10px;
        z-index: 100;
        background: #1d1d1d7d
    }
  }
  
  
  

