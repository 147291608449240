
.chat {
  position: relative;
}
.chat-visibility {
  position: absolute;
  top: 7px;
  right: 10px;
  width: 28px;
  background: #00000082 !important;
  color: white !important;
  border: none !important;
  border-radius: 6px !important;
  z-index: 1000;
  outline: none !important;
}
.chat-visibility:hover {
  color: #0ecbb2 !important;
  background: #00000082 !important;
}
.poll-visibility {
  position: absolute;
  top: 7px;
  right: 40px;
  width: 28px;
  background: #00000082 !important;
  color: white !important;
  border: none !important;
  border-radius: 6px !important;
  z-index: 1000;
  outline: none !important;
}
.poll-visibility:hover {
  color: #0ecbb2 !important;
  background: #00000082 !important;
}

.show-player button.chat-visibility:not(:disabled):not(.disabled).active, .show-player button.chat-visibility:not(:disabled):not(.disabled):active,
.show-player button.chat-visibility:hover, .show-player button.chat-visibility:active, .show-player button.chat-visibility:focus {
  background: #00000082 !important;
}
.show-player button.poll-visibility:not(:disabled):not(.disabled).active, .show-player button.poll-visibility:not(:disabled):not(.disabled):active,
.show-player button.poll-visibility:hover, .show-player button.poll-visibility:active, .show-player button.poll-visibility:focus {
  background: #00000082 !important;
}

@keyframes colorpulse {
  0% {
    color: white;
    margin-top: 15px;
    
  }
  100% {
    color: #cb0f7e;
    margin-top: 0px;
  }
}

.chat-title {
  height: 32px;
  line-height: 32px;
  font-family: 'Oswald';
  padding-left: 5px;
}

.show-messages {
    background: #1c1c1c82;
    position: relative;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;    
  }

  .show-messages .hide {
    display: none;
  }
  
  .messages-list {
    background: #00000082;
    padding: 10px;
    text-align: left;
    flex: 90;
    overflow: auto;
    font-size: 0.9rem;
  }
  

  .messages-list .date {
    color: #b5b5b5;
    font-size: 9px;
    padding-left: 10px;
    display: none;
    line-height: 12px;
  }
  .messages-list div:hover .date {
    display: inline-block;
  }
  .messages-list .username {
    color: #0ecbb2;
    /* padding-left: 15px; */
  }
  .messages-list .message {
    color: white;
    padding-left: 20px;
  }
  .messages-list .message a, .messages-list .message a:hover {
    color: white;    
  }
  .messages-list.stage {
    font-size: 1.5rem;
  }
  .messages-list .notification {
    background: #222;
    font-size: 0.9rem;
    padding-left: 5px;
    padding-right: 5px;
    border-left: solid 4px #cc077f;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
  }
  .messages-list .notification .username {
    font-weight: bold;
  }
  .messages-list .notification .date {
    padding-left: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .messages-list .notification:hover .date {
    display: flex;
    padding-left: 20px
  }
  .messages-list .notification .icon {
    width: 32px;
    height: 32px;
    background: url('/favicon-32x32.png');
    margin-right: 12px
  }
  
  .messages-footer {
    /* height: 60px; */
    padding-top: 10px;
    padding-bottom: 10px;
    background: #1c1c1c;
    /* position: absolute; */
    bottom: 0px;
    /* width: 98%; */

    flex-basis: 60px;
    display: flex;
    flex-direction: row;
  }
  
  .messages-input {
    width: 90%;
    margin-left: 10px;
    margin-right: 10px;
    background: #b7b7b7;
    height: 40px;
    border-radius: 20px;
    flex-basis: 100%;
  }
  .messages-input input {
    background: transparent;
    border: none;
    width: 90%;
    margin: 8px 10px 10px 20px;
    padding: 0px;
    color: #1c1c1c;
    outline: none !important;
  }
  .messages-input input::placeholder, 
  .messages-input input::-moz-placeholder, 
  .messages-input input::-webkit-input-placeholder {
    color: #333;
  }

  .messages-send {
      flex-basis: 44px;
      margin-right: 10px;
  }
  .messages-send button {
    padding: 7px 12px 7px 10px;
    border: solid 1px black !important;
    border-radius: 29px !important;
    background: white !important;
    color: black !important;
    outline: none !important;
  }
  .messages-send button:hover {    
    background-attachment: fixed;
    background: #0ecbb2 !important;
    color: white !important;
  }

  .messages-emoji {
    flex-basis: 44px;
    margin-right: 10px;
    position: relative;
  }
  .messages-emoji>button {
    padding: 7px 12px 7px 10px;
    border: solid 1px black !important;
    border-radius: 29px !important;
    background: white !important;
    color: black !important;
    outline: none !important;
  }
  .messages-emoji>button:hover {    
    background-attachment: fixed;
    background: #0ecbb2 !important;
    color: white !important;
  }
  .messages-emoji>button.active {    
    background-attachment: fixed;
    background: #0ecbb2 !important;
    color: white !important;
  }

  .messages-emoji .emoji-mart {
    position: absolute;
    bottom: 50px;
    right: 0px;
  }

  .messages-vivelo {
    flex-basis: 44px;
    margin-right: 10px;
    position: relative;
  }
  .messages-vivelo>button {
    padding: 7px 8px 7px 8px;
    border: solid 1px black !important;
    border-radius: 29px !important;
    background: white !important;
    color: black !important;
    outline: none !important;
  }
  .messages-vivelo>button:hover {    
    background-attachment: fixed;
    background: black !important;
    color: white !important;
  }
  .messages-vivelo>button .icon-vivelo {
    display: inline-block;
    background: url('/img/isotipo200.png') no-repeat center;
    background-size: contain;
    width: 22px;
    height: 22px;
    line-height: 22px;
  }

  .message .icon-vivelo {
    display: inline-block;
    background: url('/img/isotipo200.png') no-repeat center;
    background-size: contain;
    width: 32px;
    height: 32px;
    line-height: 32px;
  }


  .poll-container {
    background: #00000082;
    padding: 10px;
    text-align: left;
    flex: 90;
    overflow: auto;
  }

  .poll-ask {
    margin: 0;
  }

  .poll-question {
    margin: 0;
    font-size: 1.3rem;
  }

  .poll-options {
    margin: 0px;
    padding: 0px 20px 0px 20px;
  }

  .poll-options li {
    list-style-type: none;
  }
  .poll-options li .btn-link {
     width: 100%;
     text-align: left;
     color: white;
     text-decoration: none;
  }
  .poll-options li .btn-link:hover, .poll-options li .btn-link:active, .poll-options li .btn-link:focus {
    color: #0ecbb2;
    background: black;
  }

  .poll-results .poll-options li {
    margin-bottom: 10px;
  }

  .poll-result-labels {
    display: flex;
    justify-content: space-between
  }
  .poll-result-labels-title, .poll-result-labels-percent {

  }

  .poll-result-graph {
    height: 20px;
    width: 100%;
    background: #666666;
    position: relative;
    border-radius: 10px;
  }
  .poll-result-graph-label {
    color: white;
    position: absolute;
    top: 1px;
    left: 0px;
    font-size: 0.8rem;
    height: 20px;
    line-height: 20px;
    padding-left: 8px;
  } 
  .poll-result-graph-fill {
    background: #0ecbb2;
    height: 20px;
    border-radius: 10px;
  }

  .chat-session {
    display: flex;
    justify-content: center;
    align-items: center;    
    background: #1c1c1c82;
  }
  .chat-session .form {
    display: flex;
    flex-direction: column;
  }



.chat-mobile {
  margin-top: 10px;
  display: flex;
  flex-direction: row;  
}
.chat-mobile .chat-qrcode {
  padding: 5px;
  margin-right: 5px;
  width: 110px;
  height: 110px;
}
.chat-mobile .chat-label {
  font-size: 0.8rem;
  font-family: 'Montserrat';
  display: flex;
  align-items: center;
  width: 150px;
}
.chat-mobile .chat-phone {
  font-size: 45px;
  display: flex;
  align-items: center;
}