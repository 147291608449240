
.titanes2021 { 
    color: white;
    background: #09244A;    
    font-family: 'Montserrat' !important;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 25px !important;
}

.titanes2021 .titledate {
    font-weight: bold;
    font-size: 1.5rem
}

.titanes2021 h1 {
    font-family: 'Montserrat';
    color: white !important;
}

.titanes2021 .fullscreen {
    width: 100%;
    /* height: 56.25vw; */
}
.titanes2021 .fullscreen img {
    width: 100%;
    height: 100%;
}

.titanes2021 .dark-bg {
    background: #081B40;
}

.titanes2021 .white-bg {
    background: white;
}

.titanes2021 .centered {
    text-align: center;
}

.titanes2021 .title {
    font-size: 1.5rem;
    font-weight: bold;
    background: #cdab75;
    color: black;
    padding: 20px;
    margin-bottom: 20px;
}

.titanes2021 .buttons {
    padding: 50px;
}

.titanes2021 .ticket-box {
    margin: 0px;
    padding: 0px;
    text-align: center;
    /* display: flex; */
    /* justify-content: center; */
}

.titanes2021 .tickets-button {
    background: linear-gradient(180deg, #EFC28E, #AB640C);
    display: inline-block;
}
.titanes2021 .tickets-button .btn {
    border: none;
    color: black;
}

.titanes2021 .two-columns {
    padding: 0px 10px 50px 10px;
}

.titanes2021 .two-columns div h1 {
    padding: 10px;
    background: #cdab75;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.5rem;
    margin-top: 50px;
}

.titanes2021 .two-columns div p {
    padding: 10px;
    background: #00487A;
}

.titanes2021 .info {
    text-align: center;
    padding-bottom: 50px;
    font-size: 1.3rem;
}
.titanes2021 .info img {
    width: 200px;
}

.titanes2021 .video {
    height: 250px;
    text-align: center;
    padding-bottom: 50px;
    font-size: 1.3rem;
}

.titanes2021 .show-tickets {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 1rem;
    padding-top: 50px;
    padding-bottom: 50px;
}

.titanes2021 .show-tickets section label {
    font-size: 1.5rem;
    font-weight: bold;
    background: #cdab75;
    color: black;
    padding: 20px;
    margin-bottom: 20px;
    display: block;
}

.titanes2021 .show-tickets .presale-date {
    font-size: 0.7rem;
}



.titanes2021 .speaker {

}
.titanes2021 .speaker .image {
    width: 100%;
}
.titanes2021 .speaker .image img {
    width: 100%;
}
.titanes2021 .speaker .content {
    padding: 20px;
    margin-top: 0px;
}
.titanes2021 .speaker h1 {
    padding: 5px;
    font-weight: bold;
}
.titanes2021 .speaker h2 {
    padding: 5px;
    background: #00487A;
    color: white;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: bold;
}
.titanes2021 .speaker p {
    padding: 10px;
}

.titanes2021 .partners {
    text-align: center;
    padding: 10px;
}
.titanes2021 .partners img {
    margin: 20px;
    width: 200px;
}
.titanes2021 .hosts {
    text-align: center;
    padding: 10px;
}
.titanes2021 .hosts img {
    margin: 20px;
    width: 200px;
}
.titanes2021 .allagis {
    background: white;
    padding: 20px;
}
.titanes2021 .cargo {
    padding-top: 40px;
    padding-bottom: 40px;
    background: white;
}
.titanes2021 .contact-box {
    background: #cb0f7e;
    color: white;
    padding: 20px;
    text-align: center;
}
.titanes2021 .contact-box img {
    width: 150px;
    padding-bottom: 10px;
}


@media (min-width: 900px) {
    .titanes2021 {
        padding: 0px !important;        
        margin-left: 0px;
        margin-right: 0px;
    }
    .titanes2021 .ticket-box {
        width: 500px;
        justify-content: center;
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
    }
    .titanes2021 .ticket-box .row {
        margin-left: 5px;
        margin-right: 5px;
    }
    .titanes2021 .two-columns {
        display: grid;
        grid-template-columns: 40% 40%;
        grid-column-gap: 10%;
        justify-content: center;
    }
    .titanes2021 .video {
        height: 400px;
    }
    .titanes2021 .speaker {
        display: grid;
        grid-template-columns: 40% 40%;
        grid-column-gap: 10%;
        justify-content: center;
        padding-top: 40px;
        padding-bottom: 20px;
    }

    .titanes2021 .partners img {
        /* margin: 20px; */
        width: 400px;
        margin: 20px 50px;
    }
    .titanes2021 .hosts img {
        /* margin: 20px; */
        width: 300px;
    }
}


/* PLAYER */

.titanes2021.player {
    background: #09244A !important;
    margin-top: 0px !important;
}
.titanes2021 .show-header {
    background: #09244A;
    color: white;
    /* border-bottom: solid 3px #E1B340; */
    padding: 0px 20px 0px 0px;
  }
  .titanes2021 .show-header .tema {
    width: 262px;
    height: 80px;
  }
  .titanes2021 .show-header .tema img {
    width: 100%;
    height: 100%;
  }
  .titanes2021 .show-header .tema img.desktop {
    display: inline-block;
  }
  .titanes2021 .show-header .tema img.mobile {
    display: none;
  }

  .titanes2021 .video-selector {
      /* background: #1C1C1C; */
      height: 60px;
      display: flex;
      /* justify-content: flex-end; */
      align-items: center;
      padding: 12px;
  }
  .titanes2021 .video-selector .btn.active {
      background: #BC8E45;      
      font-weight: bold;
  }
  .titanes2021 .video-player {
      background-image: url('https://assets.viveloonline.com/shows/6005e963eaf3235389ee7b20/Portada BN.png');
  }

  .titanes2021 .show-footer {
    background: #09244A;
  }
  
  .titanes2021 .brand-footer {
    
  }
  .titanes2021 .brand-footer img {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
  }
  .titanes2021 .brand-footer img.desktop {
    display: inline-block;
  }
  .titanes2021 .brand-footer img.mobile {
    display: none;
  }


  
  @media (max-width: 992px) {
    .titanes2021 .show-header {
      background-size: cover;
      position: fixed;
      top:0;
      left:0;
      right:0;
      z-index: 9999;
    }
    .titanes2021 .show-header .tema {
      width: 115px;
      height: 35px;    
    }
    .titanes2021 .show-header .tema img.desktop {
      /* display: none; */
    }
    .titanes2021 .show-header .tema img.mobile {
      /* display: inline-block; */
    }
    .titanes2021 .show-player {
        position: fixed;
        top: 35px;
        z-index: 9999;
    }
    .titanes2021 .show-interactions {
        /* margin-top: 260px; */
    }
    .titanes2021 .brand-footer {
      
    }
    .titanes2021 .brand-footer img {
        width: 100%;
    }
    .titanes2021 .brand-footer img.desktop {
        display: none;
      }
      .titanes2021 .brand-footer img.mobile {
        display: inline-block;
      }
    .titanes2021 .small-logo {
      width: 56px !important;
      height: 20px !important;
      margin-top: -4px;
    }
    .titanes2021 .show-header .social-icons {
        position: fixed;
        bottom: 10px;
        z-index: 100;
        background: #1d1d1d7d
    }
  }
  
  
  

