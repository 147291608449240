
.site-jandirvolar { 
    color: white;
    font-family: 'Montserrat' !important;
    padding-left: 0px;
    padding-right: 0px;
    /* margin-top: 105px !important; */
    background-color: black;    
}

.site-jandirvolar .header .background {
  display: block;
  width: 100%;
  height: 56.25vw;  
  background-position: center center;
  background-size: cover;  
  background-image: url("http://assets.viveloonline.com/shows/5eed57a46ba924af375b7178/juevesdetrova-20200626-portada.png");
  z-index: -1;
  /* position: absolute; */
  top:0;
  left:0;
}
.site-jandirvolar .header .background-mobile {
  display: none;
  width: 100%;
  height: 162.66vw;
  background-position: top center;
  background-size: cover;  
  /* background-image: url("http://assets.viveloonline.com/shows/5eed57a46ba924af375b7178/juevesdetrova-20200626-portada.png"); */
  z-index: -1;
  /* position: absolute; */
  top:0;
  left:0;
}

.site-jandirvolar .channel-info {
  margin-top: -200px;
  padding: 0px 100px;
}

.site-jandirvolar .channel-info .show-title {
  font-weight: bold
}

.site-jandirvolar .channel-info .show-badges {
  font-size: 10px;
  display: flex;
  justify-content: flex-start;
  line-height: 21px;  
}
.site-jandirvolar .channel-info .show-year {
  margin-right: 10px;
  line-height: 21px;
  height: 21px;
}
.site-jandirvolar .channel-info .show-rating {
  margin-right: 10px;
  padding: 4px 4px 0px 6px;
  border: solid 1px #808080;
  line-height: 12px;
  height: 21px;
}
.site-jandirvolar .channel-info .show-quality {
  margin-right: 10px;
  padding: 4px 4px 0px 6px;
  border: solid 1px #808080;
  line-height: 12px;
  height: 21px;
}
.site-jandirvolar .channel-info .show-description {
  margin-top: 30px;
  width: 70%;
}

/* ************************ */

.site-jandirvolar .body {
  /* padding: 20px 100px;   */
  padding: 20px 0px;  
}

.site-jandirvolar .body .show-info {
  background: #404040;
  padding: 40px;
  display: flex;
  justify-content: center;
}


.site-jandirvolar .show-info .show-info-border {
  border: solid 8px #BFD96E;
  padding: 20px;
  max-width: 1300px;
}
.site-jandirvolar .show-info .show-info-header {
  display: flex;
  flex-direction: row;
}
.site-jandirvolar .show-info .album-image {
  padding: 10px;
  width: 320px;
  height: 320px;
  padding: 0px 20px 20px 0px;
}
.site-jandirvolar .show-info .album-image img {
  width: 100%;
  height: 100%;
}
.site-jandirvolar .show-info .album-info {  
  text-transform: uppercase;  
}
.site-jandirvolar .show-info .album-info .album-title {  
  font-size: 36px;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 15px;
}
.site-jandirvolar .show-info .album-info .album-subtitle {  
  font-size: 26px;
  line-height: 26px;
  color: #BFD96E;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 5px;
  padding-bottom: 5px;
}
.site-jandirvolar .show-info .album-info .show-date {  
  font-size: 24px;
  line-height: 26px;
  color: white;
  font-weight: 600;
  text-transform: uppercase;  
}
.site-jandirvolar .show-info .album-info .show-date-alternate {  
  font-size: 14px;
  color: white;
  text-transform: uppercase;
  padding-bottom: 5px;
}
.site-jandirvolar .show-info .album-info .show-venue {  
  font-size: 18px;
  color: white;
  text-transform: uppercase;
  border-bottom: solid 1px white;
  padding-bottom: 20px;
  letter-spacing: 3px;
}

.site-jandirvolar .show-info .album-info .ticket-box {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  padding-top: 20px;
  align-items: center;  
}
.site-jandirvolar .show-info .show-play button {
  background: #BFD96E;
  border: none;
  padding: 10px 40px;
  border-radius: 10px;
  color: #404040;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 600;
  margin: 0px;
  letter-spacing: 2px;
}
.site-jandirvolar .show-info .show-play button:hover {
  background: #d2e98c;
}

.site-jandirvolar .show-info .ticket-box .show-ticket-info {
  font-size: 14px;
  letter-spacing: 2px;
}

.site-jandirvolar .show-info .ticket-info {
  margin-top: 20px;
  border-top: solid 1px white;
  padding: 10px 0px;  
  
}
.site-jandirvolar .show-info .ticket-info .ticket-info-layout {
  display: flex;
  justify-content: flex-start;
  column-gap: 10px;
}

.site-jandirvolar .show-info .ticket-info label {
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
}
.site-jandirvolar .show-info .ticket-info .ticket-prices {
  /* width: 400px; */
}
.site-jandirvolar .show-info .ticket-info ul {
  width: 400px;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  padding: 0px;    
}
.site-jandirvolar .show-info .ticket-info ul li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
  margin: 0px;
  /* font-weight: bold; */
  
}
.site-jandirvolar .show-info .ticket-info .ticket-buttons {
  text-align: right;
  display: flex;
  flex-direction: row;  
  justify-content: flex-end;
}
.site-jandirvolar .show-info .ticket-info .ticket-buttons .ticket {
  display: flex;
  flex-direction: row;
  align-items: center;  
}
.site-jandirvolar .show-info .ticket-info .ticket-buttons .hole-start {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: #404040;
  margin-right: -8px;
  z-index: 10;
}
.site-jandirvolar .show-info .ticket-info .ticket-buttons .hole-end {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: #404040;
  margin-left: -8px;
  z-index: 10;
}

.site-jandirvolar .show-info .ticket-info .ticket-buttons button {
  background: #BFD96E;
  border: none;
  padding: 10px 40px;
  border-radius: 10px;
  color: #404040;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  margin: 0px;
  line-height: 16px;
  min-width: 290px;
  letter-spacing: 2px;
}
.site-jandirvolar .show-info .ticket-info .ticket-buttons button:hover {
  background: #d2e98c;
}


.site-jandirvolar .show-info .ticket-info .ticket-buttons button span {
  font-size: 8px;
  font-weight: lighter;
  white-space: nowrap;
  line-height: 8px;
}

.site-jandirvolar .show-info .ticket-info .ticket-buttons button:disabled,
.site-jandirvolar .show-info .ticket-info .ticket-buttons button[disabled] {
  background: #949494;
}

.site-jandirvolar .show-info .ticket-info .ticket-sales {
  text-transform: uppercase;
  font-size: 12px;
  padding-left: 10px;
}
.site-jandirvolar .show-info .ticket-info .ticket-sales .ticket-sales-info {
  letter-spacing: 3px;
  padding-bottom: 10px;
}
.site-jandirvolar .show-info .ticket-info .ticket-sales .ticket-sales-place {
  display: grid;
  grid-template-columns: 50px auto;
  column-gap: 10px;
}
.site-jandirvolar .show-info .ticket-info .ticket-sales .ticket-sales-place .place-icon {
  
}
.site-jandirvolar .show-info .ticket-info .ticket-sales .ticket-sales-place .place-icon img {
  width: 50px;
  height: 50px;
}
.site-jandirvolar .show-info .ticket-info .ticket-sales .ticket-sales-place dl {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50px;
  padding: 0px;
  margin: 0px;
}
.site-jandirvolar .show-info .ticket-info .ticket-sales .ticket-sales-place dt {
  font-size: 14px;
  letter-spacing: 3px;
}
.site-jandirvolar .show-info .ticket-info .ticket-sales .ticket-sales-place dd {
  font-size: 12px;
  padding: 0px;
  margin: 0px;
}

/* ************************ */

.site-jandirvolar .song-container {
  border-top: solid 1px #4B4B4B;
  margin-top: 40px;
  padding: 40px 40px 0px 40px;
  
  display: grid;
  grid-template-columns: 380px auto 100px;
  column-gap: 30px;
}

.site-jandirvolar .song-container .song-cover {
  width: 380px;
  height: 380px;
}
.site-jandirvolar .song-container .song-cover img {
  width: 100%;
  height: 100%;
}
.site-jandirvolar .song-container .song-info {
  
}
.site-jandirvolar .song-container .song-info h1 {
  font-size: 1.3rem;
  font-weight: bold;
  padding: 0;
  margin: 0;
}
.site-jandirvolar .song-container .song-info label {
  font-size: 0.8rem;
  text-transform: uppercase;
  
}
.site-jandirvolar .song-container .song-info dl {
  padding-top: 20px;
  display: grid;
  grid-template-columns: 150px auto;
  font-size: 0.7rem;
}
.site-jandirvolar .song-container .song-info dl dt {
  /* float: left; */
  margin: 0;
}
.site-jandirvolar .song-container .song-info dl dd {
  /* float: left; */
  margin: 0;
}

.site-jandirvolar .song-video {
  justify-content: center;
  text-align: center;
  padding: 20px 0px;
}
.site-jandirvolar .song-video h1 {  
  justify-content: center;
  font-size: 1.2rem;
  font-weight: bold;
}
.site-jandirvolar .song-video .song-video-container {
  display: flex;
  justify-content: center;
  padding: 20px 0px;
}
.site-jandirvolar .song-video .song-video-container .youtube {
  width: 800px;
  height: 450px;
}
.site-jandirvolar .song-video .song-video-container .placeholder {
  width: 80%;
}
.site-jandirvolar .song-video .song-video-container .vimeo {
  width: 800px;
  height: 450px;
  justify-content: center;
  align-items: center;
}


.site-jandirvolar .song-gallery {  
  justify-content: center;
  text-align: center;
  padding: 20px 0px;
}
.site-jandirvolar .song-gallery h1 {
  justify-content: center;
  font-size: 1.2rem;
  font-weight: bold;
}
.site-jandirvolar .song-gallery .gallery-container {
  display: flex;
  justify-content: center;
  /* width: 80%; */
}
.site-jandirvolar .song-gallery .gallery-container .carousel {
  width: 800px;
}

.site-jandirvolar .social-links {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  padding: 5px 0px;
}
.site-jandirvolar .social-links a {
  text-decoration: none;
  white-space: nowrap;
}
.site-jandirvolar .social-links a:hover {
  text-decoration: underline;
}
.site-jandirvolar .custom-icon {
  width: 30px;
  height: 30px;
  display: inline-block;
  
}
.site-jandirvolar .custom-icon.deezer {
  background-image: url('https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/jam_deezer-circle.png')
}


.site-jandirvolar .footer {
  background: #414141;
  padding: 30px;
  margin: 50px 100px;    
}
.site-jandirvolar .footer h2 {
  font-size: 1.3rem;
}
.site-jandirvolar .footer .content {
  display: grid;
  grid-template-columns: auto 200px;
  text-align: justify;
}
.site-jandirvolar .footer .content .logos {
  padding-left: 20px;
}
.site-jandirvolar .footer .logos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
.site-jandirvolar .footer .logos img {
  /* height: 150px; */
}

@media (max-width: 768px) {

  .site-jandirvolar {
    margin-top: 0px !important;
  }
  .site-jandirvolar .header .background {
    display: none;
  }
  .site-jandirvolar .header .background-mobile {
    display: block;
  }
  .site-jandirvolar .channel-info {
    margin-top: -150px;
    padding: 0px 20px;
  }
  
  .site-jandirvolar .channel-info .show-description {
    margin-top: 30px;
    width: 100%;
  }

  /* ************************ */

  .site-jandirvolar .body {
    padding: 30px 0px;
  }
  .site-jandirvolar .body .show-info {
    padding: 40px 20px;
  }

  .site-jandirvolar .show-info .show-info-border {
    padding: 10px;
    max-width: 100%;
    border-width: 4px;
  }
  .site-jandirvolar .show-info .show-info-header {
    display: flex;
    flex-direction: column;    
  }
  .site-jandirvolar .show-info .show-description {
    padding-top: 20px;
    text-align: justify;
  }
  .site-jandirvolar .show-info .album-info .ticket-box {
    flex-direction: column;
  }
  .site-jandirvolar .show-info .ticket-info .ticket-info-layout {
    flex-direction: column;
  }
  .site-jandirvolar .show-info .ticket-info ul {
    width: 100%;
  }
  .site-jandirvolar .show-info .ticket-info .ticket-buttons {
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
  }

  .site-jandirvolar .song-container {
    display: grid;
    grid-template-columns: 300px;
    row-gap: 30px;

  }
  .site-jandirvolar .song-container .song-cover {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .site-jandirvolar .song-gallery .carousel {
    width: 300px;
    /* overflow-y: none; */
    /* overflow-x: auto; */
  }

  .site-jandirvolar .song-video .song-video-container .youtube {
    width: 300px;
    height: 168px;
  }
  .site-jandirvolar .song-video .song-video-container .vimeo {
    width: 300px;
    height: 168px;
  }

  .site-jandirvolar .song-gallery .gallery-container .carousel {
    width: 100%;
  }

  .site-jandirvolar .footer {
    padding: 30px;
    margin: 0px 20px;
  }
  .site-jandirvolar .footer .content {
    display: inline-block;
    margin: 0px;    
  }
  .site-jandirvolar .footer .content .logos {
    padding: 0px;
  }

}
