


.show-container {
  /* width: 80%; */
  margin-top: 20px;
}
.show-container .small-logo {
  width: 112px;
  height: 40px;
  /* line-height: 60px; */
}

.show-container .show-header .col {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center;
}
.show-container .show-header .show-title {
  flex-basis: 90
}
.show-container .show-header .live {
  flex-basis: 112px;
  display: inline-block;
  width: 112px;
  padding-top: 10px;
}
.show-container .show-header .small-logo {
  flex-basis: 112px;
  display: inline-block;
  width: 112px;
}

.show-player {
  background: #1c1c1c;
  width: 100%;
  position: relative;
  padding: 5px;
}
/*.video-player {
  background-color: black;
  background-image: url('../img/show1.jpg');    
  width: 900px;
  height: 506px;
  margin: 5px;
}*/
.video-player {
  background-color: black;
  background-image: url('/img/show1.jpg');
  background-size: cover;
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}
.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.react-player iframe {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
}

.show-player button {
  background: transparent;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  outline: none;
}
.show-player button:not(:disabled):not(.disabled).active, .show-player button:not(:disabled):not(.disabled):active,
.show-player button:hover, .show-player button:active, .show-player button:focus {
  /*background: #121212;*/
  background: transparent;
  background-color: transparent !important;
  color: #fa5504;  
  border: none;
  border-color: transparent !important;
  border-radius: 0px;
  box-shadow: none;
  outline: none;
}

.show-player .chat-box .chata, .show-player .chat-box .chat .show-messages {
  height: 500px;
}

.show-player .title {
  display: none;
}

.show-ended {
  /*width: 1100px;*/
  width: 100%;
  height: 618px;
}
.show-ended .content {
  text-align: center;
  padding: 0px 10px;
}
.show-ended-isotipo {
  width: 100px;
  height: 100px;
}
.show-ended-title {
  font-size: 1.4rem
}
.show-ended-comment {
  font-family: 'Montserrat';  
}


@media (min-width: 992px) {
  .show-player .chat-box {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 500px;
    height: 300px;
    z-index: 900;
  }

  /* .show-ended {
    width: 920px;
    height: 517px;
  }   */
}

@media (max-width: 992px) {
  .show-container {
    /* width: 80%; */
    margin-top: 5px;
  }  
  .show-container .show-header {
    height: 38px;
  }
  .show-container .show-header .col {
    flex-direction: row-reverse !important;
    flex-wrap: wrap-reverse ;
    justify-content: flex-end;
  }
  .show-container .show-header .show-title {
    font-size: 1.2rem;
    padding-top: 15px;
    display: none;
  }
  .show-container .show-header .live {
    text-align: right;
    padding-left: 30px;
    font-size: 0.8rem;
    padding-top: 0px;
  }
  .show-container .small-logo {
    width: 70px !important;
    height: 25px !important;
    /* line-height: 60px; */
  }
  .show-player .chat-box {    
    width: 100%;
    min-height: 42px;        
  }
  .show-player .title {
    display: block;    
    font-size: 0.8rem;
  }
  
  .show-player .chat-box .chata, .show-player .chat-box .chat .show-messages {
    height: 300px;
  }

  .show-player .chat-box .chata, .show-player .chat-box .chat .show-messages {
    flex-direction: column-reverse;
    padding-top: 40px;
  }

  .show-ended {
    width: 100%;
    height: 400px;
  }
}

@media (max-width: 768px) {
  .show-ended {
    width: 100%;
    height: 281px;
  }
}
/*
@media (max-width: 667px) {
  .Home .soon {    
    width: 510px;
    min-height: 178px;
  }   
}
@media (max-width: 576px) {
  .Home .soon {    
    width: 354px;
    min-height: 124px;
  }    
}
*/
@media (max-width: 375px) {
  .show-ended {
    width: 100%;
    height: 169px;
  }  
  .show-ended-isotipo {
    width: 50px;
    height: 50px;
  }
  .show-ended-title {
    font-size: 1rem;
  }
  .show-ended-comment {
    font-size: 0.8rem;
  }
}
/*
@media (max-width: 320px) {
  .Home .soon {    
    width: 250px;
    min-height: 87px;
    margin-bottom: 20px;
  }    
} */

