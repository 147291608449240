
.fafidess2021 { 
    color: white;       
    font-family: 'Montserrat' !important;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 25px !important;
    background-color: #011E5E;
    background: repeat-y url('https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/bg.jpg') center top;
    background-size: contain;
}

.fafidess2021 .separator {
    background:  repeat-x url('https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/Asset 18.png') center;
    height: 5px;
    margin: 10px 0px;
}

.fafidess2021 .titledate {
    font-weight: bold;
    font-size: 1.5rem
}

.fafidess2021 h1 {
    font-family: 'Montserrat';
    color: white !important;
}


.fafidess2021 .dark-bg {    
}

.fafidess2021 .white-bg {
    background: white;
}

.fafidess2021 .centered {
    text-align: center;
}

.fafidess2021 .main-desktop {
    display: none;
    text-align: center;
}
.fafidess2021 .main-desktop img {
    width: 100%;
    /* width: 200px; */
}
.fafidess2021 .main-mobile {
    display: block;
    text-align: center;
}
.fafidess2021 .main-mobile img {
    width: 100%;
    /* width: 200px; */
}

.fafidess2021 .welcome {
    text-align: center;    
}
.fafidess2021 .marcos {    
    text-align: center;
}
.fafidess2021 .marcos img {
    width: 80%;
}
.fafidess2021 .left-content {
    padding: 20px;
    text-align: left;
}
.fafidess2021 .left-content .img-marcos {
    width: 100%;
}
.fafidess2021 .left-content .img-date {
    width: 80%;
}

.fafidess2021 .register {
    width: 280px;
    border-radius: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 20px;    
}
.fafidess2021 .play {
    width: 280px;
    border-radius: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 20px;    
}

.fafidess2021 .title {
    font-size: 1.5rem;
    font-weight: bold;
    padding: 20px;
    margin-bottom: 20px;
    text-align: center;
    text-transform: uppercase;
}

.fafidess2021 .buttons {
    padding: 50px;
    text-align: center;
}

.fafidess2021 .ticket-box {
    margin: 0px;
    padding: 0px;
    text-align: center;    
}

.fafidess2021 .tickets-button {
    background: linear-gradient(180deg, #EFC28E, #AB640C);
    display: inline-block;
}
.fafidess2021 .tickets-button .btn {
    border: none;
    color: black;
}
.fafidess2021 .left-content .img-marcos {
    margin-left: -20px;
    margin-bottom: -20px;
}
.fafidess2021 .img-marcos2 {
    width: 400px;
    margin-bottom: -20px;
    margin-left: -10px;
}
.fafidess2021 .img-mac {
    width: 400px;
    margin-bottom: -20px;
    margin-left: -10px;
}

.fafidess2021 .two-columns {
    padding: 0px 10px 50px 10px;
    text-align: center;
}

.fafidess2021 .two-columns div h1 {
    padding: 10px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.5rem;
    margin-top: 50px;
}

.fafidess2021 .two-columns div p {
    padding: 10px;    
    text-align: justify;
}

.fafidess2021 .info {

}
.fafidess2021 .info img {
    
}

.fafidess2021 .video {
    height: 550px;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
    font-size: 1.3rem;
}

.fafidess2021 .show-tickets {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 1rem;
    padding-top: 50px;
    padding-bottom: 50px;
}

.fafidess2021 .show-tickets section label {
    font-size: 1.5rem;
    font-weight: bold;
    color: black;
    padding: 20px;
    margin-bottom: 20px;
    display: block;
}

.fafidess2021 .show-tickets .presale-date {
    font-size: 0.7rem;
}



.fafidess2021 .speaker {
    padding-top: 20px;
    text-align: center;
}
.fafidess2021 .speaker .image {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
}
.fafidess2021 .speaker .image img {
    width: 100%;
}
.fafidess2021 .speaker .content {
    padding: 20px;
    margin-top: 0px;
}
.fafidess2021 .speaker h1 {
    padding: 5px;
    font-weight: bold;
    text-transform: uppercase;
}
.fafidess2021 .speaker h2 {
    padding: 5px;
    color: white;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: bold;
}
.fafidess2021 .speaker p {
    padding: 10px;
    text-align: justify;
}


.fafidess2021 .servicios-financieros {
    text-align: center;    
}
.fafidess2021 .servicios-financieros .grid-financieros {
    
}
.fafidess2021 .servicios-financieros .grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
}
.fafidess2021 .servicios-financieros .grid-item label {
    font-weight: bold;
}
.fafidess2021 .servicios-financieros .grid-item img {
    /* width: 200px; */
}

.fafidess2021 .servicios-financieros .btn-fafidess {
    background: no-repeat url('https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/Asset 8.png') center center;
    width: 315px;
    height: 89px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.fafidess2021 .servicios-financieros .btn-fafidess a {
    text-decoration: none;
    font-weight: bold;
    font-size: 20px;
}


.fafidess2021 .servicios-empresariales {
    text-align: center;
}
.fafidess2021 .servicios-empresariales .grid-financieros {

}
.fafidess2021 .servicios-empresariales .grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
}
.fafidess2021 .servicios-empresariales .grid-item img {
    /* width: 200px; */
}

.fafidess2021 .contacto {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 50px;
}



.fafidess2021 .partners {
    text-align: center;
    padding: 10px;
}
.fafidess2021 .partners img {
    margin: 10px;
    width: 100px;
}

.fafidess2021 .contact-box {
    background: #cb0f7e;
    color: white;
    padding: 20px;
    text-align: center;
}
.fafidess2021 .contact-box img {
    width: 150px;
    padding-bottom: 10px;
}

.fafidess2021 .grid-financieros {
    display: grid;
    grid-template-columns: 200px 200px;
    grid-template-rows: 200px;
    width: 400px;
    margin-left: auto;
    margin-right: auto;
}

.fafidess2021 .grid-empresariales {
    display: grid;
    grid-template-columns: 200px 200px;
    grid-template-rows: 200px;
    width: 400px;
    margin-left: auto;
    margin-right: auto;
}

.fafidess2021 .grid-item img {
    width: 150px;
}

@media (min-width: 900px) {
    .fafidess2021 {
        padding: 0px !important;        
        margin-left: 0px;
        margin-right: 0px;
    }
    .fafidess2021 .main-desktop {
        display: block;
    }
    .fafidess2021 .main-mobile {
        display: none;
    }
    
    .fafidess2021 .welcome {
        display: grid;
        grid-template-columns: 50% 50%;        
    }
    .fafidess2021 .marcos {    
        order: 2;
    }
    .fafidess2021 .marcos img {
        
    }
    .fafidess2021 .left-content {
        padding: 20px;
        text-align: left;
        order: 1;
        font-size: 24px;
        padding-left: 50px;
        padding-top: 60px;
    }
    .fafidess2021 .left-content .img-marcos {
        margin-left: -20px;
        margin-bottom: -20px;
    }
    .fafidess2021 .img-marcos2 {
        width: 400px;
        margin-bottom: -20px;
        margin-left: -10px;
    }
    .fafidess2021 .img-mac {
        width: 500px;
        margin-bottom: -20px;
        margin-left: -10px;
    }
    .fafidess2021 .left-content .img-date {
        width: 300px;
    }


    .fafidess2021 .ticket-box {
        width: 500px;
        justify-content: center;
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
    }
    .fafidess2021 .ticket-box .row {
        margin-left: 5px;
        margin-right: 5px;
    }
    
    .fafidess2021 .header-mision {
        text-align: center;
    }
    .fafidess2021 .header-mision img {
        width: 250px;
        margin-bottom: -50px;
    }
    .fafidess2021 .two-columns {
        display: grid;
        grid-template-columns: 40% 40%;
        grid-column-gap: 10%;
        justify-content: center;
    }
    .fafidess2021 .two-columns div p {
        text-align: justify;
        font-size: 20px;
    }

    .fafidess2021 .video {
        height: 400px;
        text-align: center;
    }
    .fafidess2021 .video h1 {
        font-size: 20px;
        font-weight: bold;
    }
    .fafidess2021 .speaker {
        display: grid;
        grid-template-columns: 45% 50%;
        grid-column-gap: 5%;
        justify-content: center;
        padding-top: 40px;
        padding-bottom: 20px;
    }
    .fafidess2021 .speaker .content {
        text-align: justify;
        font-size: 20px;
        padding-right: 40px;
    }

    .fafidess2021 .grid-financieros {
        display: grid;
        grid-template-columns: 300px 300px 300px;
        grid-template-rows: 300px 300px;
        width: 900px;
        margin-left: auto;
        margin-right: auto;
    }

    .fafidess2021 .grid-financieros .grid-item img {
        width: 200px;
    }

    .fafidess2021 .grid-empresariales {
        display: grid;
        grid-template-columns: 220px 220px 220px 220px;
        grid-template-rows: 220px;
        width: 880px;
        margin-left: auto;
        margin-right: auto;
    }

    .fafidess2021 .servicios-empresariales .grid-item img {
        width: 200px;
    }

    .fafidess2021 .partners img {
        /* margin: 20px; */
        width: 150px;
        margin: 10px 40px;
    }
    .fafidess2021 .hosts img {
        /* margin: 20px; */
        width: 300px;
    }
}


/* PLAYER */

.fafidess2021.player {
    background: #032e72 !important;
    background-image: none;
    margin-top: 0px !important;
}
.fafidess2021 .show-header {
    display: block;
    padding: 0;
    background: #032e72;
    color: white;        
    background: #032e72 url('https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/sparks top2.png') no-repeat top left;
  }  
  .fafidess2021 .show-header .sparkBottom {
    background: url('https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/sparks bottom2.png') no-repeat bottom right;
    background-position: bottom right;    
    padding: 10px;
  }
  .fafidess2021 .show-header .gold-border {
    background: rgb(206,186,128);
    background: linear-gradient(145deg, rgba(206,186,128,1) 0%, rgba(69,32,15,1) 31%, rgba(241,208,11,1) 45%, rgba(255,255,255,1) 59%, rgba(241,208,11,1) 72%, rgba(170,113,22,1) 81%, rgba(202,171,102,1) 100%);
    padding: 4px;
  }
  .fafidess2021 .show-header .header-content {
    background: #032e72;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;    
    padding: 10px 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .fafidess2021 .show-header .tema {
    width: 140px;    
    /* height: 80px; */
  }
  .fafidess2021 .show-header .tema img {
    width: 100%;
    height: 100%;
  }
  .fafidess2021 .show-header .tema img.desktop {
    display: inline-block;
    margin: -20px;
    margin-left: 20px;
  }
  .fafidess2021 .show-header .tema img.mobile {
    display: none;
    margin: -20px;
    margin-left: 0px;
  }
  .fafidess2021 .show-header .contacto {
    font-size: 10px;
    width: 100px;
    padding: 0px;
    /* align-items: center; */
  }
  .fafidess2021 .show-header .contacto img {
      width: 20px;
  }

  .fafidess2021 .video-selector {
      /* background: #1C1C1C; */
      height: 60px;
      display: flex;
      /* justify-content: flex-end; */
      align-items: center;
      padding: 12px;
  }
  .fafidess2021 .video-selector .btn.active {
      background: #BC8E45;      
      font-weight: bold;
  }
  .fafidess2021 .video-player {
      background-image: url('https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/Portada BN.png');
  }

  .fafidess2021 .show-footer {
    background-color: #032e72;    
    background: #032e72 url('https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/sparks top.png') no-repeat top left;
    /* background: url('https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/sparks.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%; */
    
  }
  .fafidess2021 .show-footer .sparkBottom {
    background:url('https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/sparks bottom.png') no-repeat bottom right;
    background-position: bottom right;
    padding: 40px;
  }

  .fafidess2021 .show-footer .gold-border {
    background: rgb(206,186,128);
    background: linear-gradient(145deg, rgba(206,186,128,1) 0%, rgba(69,32,15,1) 31%, rgba(241,208,11,1) 45%, rgba(255,255,255,1) 59%, rgba(241,208,11,1) 72%, rgba(170,113,22,1) 81%, rgba(202,171,102,1) 100%);
    padding: 8px;
  }
  .fafidess2021 .show-footer .footer-content {
    background: #032e72;
    text-align: center;
  }
  .fafidess2021 .show-footer .footer-logo img {
    width: 300px;
    margin: -20px;
  }
  .fafidess2021 .show-footer .footer-legend {
      font-weight: bold;
      font-size: 24px;
      padding-bottom: 25px;
  }
  .fafidess2021 .show-footer .grid-financieros {
    display: grid;
    grid-template-columns: 150px 150px 150px 150px 150px 150px;
    grid-template-rows: 200px;
    width: 900px;
    margin-left: auto;
    margin-right: auto;
}
.fafidess2021 .show-footer .grid-financieros .grid-item label {
    font-size: 12px;
}
.fafidess2021 .show-footer .grid-financieros .grid-item img {
    width: 125px;
}  
.fafidess2021 .show-footer .contacto img {
    width: 30px;
}

.fafidess2021 .help-box {
    padding: 30px;
}

  
  @media (max-width: 992px) {
    .fafidess2021 .show-header {
        height: 90px;
        /* position: fixed; */
    }
    .fafidess2021 .show-header .tema {
      width: 85px;
      /* height: 35px;     */
    }
    .fafidess2021 .show-header .tema img.desktop {
      display: none;
    }
    .fafidess2021 .show-header .tema img.mobile {
      display: inline-block;
    }
    .fafidess2021 .show-header .header-legend {
        width: 50%;
    }
    .fafidess2021 .show-header .header-legend img {
        width: 100%;
    }
    .fafidess2021 .show-header .contacto {
        font-size: 7px;
        width: 100px;
        padding: 0px;
        /* align-items: center; */
      }
      .fafidess2021 .show-header .contacto img {
          width: 15px;
      }
    .fafidess2021 .show-player {
        /* position: fixed;
        top: 90px;
        z-index: 9999; */
    }
    .fafidess2021 .show-footer {
       
    }
    .fafidess2021 .show-footer .footer-logo img {
        width: 200px;
        margin: -20px;
      }
    .fafidess2021 .show-footer .sparkBottom {
        padding: 20px;
    }
    .fafidess2021 .show-footer .footer-legend {
        font-weight: bold;
        font-size: 18px;        
        padding: 0px 20px 25px 20px;
    }
    .fafidess2021 .show-footer .grid-financieros {
      display: grid;
      grid-template-columns: 33% 33% 33%;
      grid-template-rows: 120px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
    .fafidess2021 .show-footer .grid-financieros .grid-item label {
        font-size: 8px;
    } 
    .fafidess2021 .show-footer .grid-financieros .grid-item img {
        width: 80px;
    } 
    .fafidess2021 .show-interactions {
        /* margin-top: 260px; */
    }
    .fafidess2021 .brand-footer {
      
    }
    .fafidess2021 .brand-footer img {
        width: 100%;
    }
    .fafidess2021 .brand-footer img.desktop {
        display: none;
      }
      .fafidess2021 .brand-footer img.mobile {
        display: inline-block;
      }
    .fafidess2021 .small-logo {
      width: 56px !important;
      height: 20px !important;
      margin-top: -4px;
    }
    .fafidess2021 .show-header .social-icons {
        position: fixed;
        bottom: 10px;
        z-index: 100;
        background: #1d1d1d7d
    }
  }
  
  
  

