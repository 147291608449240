
.marimbas2024 { 
    color: white;
    background: white;    
    font-family: 'Montserrat' !important;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 25px !important;
}

.marimbas2024 .show-header {
    padding: 0px;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    border-bottom: solid 15px #009FE3;
}
.marimbas2024 .show-header .header {
    width: 100%;
}
.marimbas2024 .show-header .header img {
    width: 100%;
}

.marimbas2024 .show-footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-top: solid 15px #009FE3;
}

.marimbas2024 .show-footer .logos {
    width: 70%;    
}
.marimbas2024 .show-footer .logos img {
    width: 100%;
}
.marimbas2024 .show-footer .footer {
    width: 100%;
}
.marimbas2024 .show-footer .footer img {
    width: 100%;
}

.marimbas2024 .show-content {
    margin: 100px;
    border: solid 5px #1E72B9;
    border-radius: 10px;
    overflow: hidden;
}


.marimbas2024 .desktop {    
    display: flex;
}
.marimbas2024 .mobile {
    width: 100%;
    display: none;
}

.marimbas2024 .show-player {
    background: black;
    color: white;
}

@media (max-width: 900px) {
    .marimbas2024 .desktop {    
        display: none;
    }
    .marimbas2024 .mobile {        
        display: flex;
    }
    
    .marimbas2024 .show-content {
        margin: 0px 0px 0px 0px;
        border: none;
        border-radius: 0px;

    }

    .marimbas2024 .show-header {        
        border-bottom-width: 5px;
    }    
    
    .marimbas2024 .show-footer {
        border-top-width: 5px;
    }
}
  
  

