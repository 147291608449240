
.viernesverde30 { 
    color: black;
    background: #EBD8BA;    
    /* font-family: 'Roboto' !important; */
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 25px !important;
}

/* .viernesverde30 .viernes-container {
    position: relative;
} */
.viernes-hide-mobile {
    display: none;
}

.viernesverde30 .viernes-bg-body {
    background: #676A53 url(https://assets.viveloonline.com/sites/viernesverde30/viernes-bg-body-mobile.png) repeat-y;
    background-repeat: repeat-y;
    background-size: 100%;
    /* height: 700px; */
    border-radius: 35px;
    position: relative;
    margin-top: 40px;
    /* display: flex; */
}
.viernesverde30 .viernes-bg-header {
    background: url(https://assets.viveloonline.com/sites/viernesverde30/viernes-bg-header-mobile.png);
    background-repeat: no-repeat;
    background-size: 100%;
    padding-top: 51.20%;
    width: 100%;
    position: absolute;
    top: 0px;
}
.viernesverde30 .viernes-bg-footer {
    background: url(https://assets.viveloonline.com/sites/viernesverde30/viernes-bg-footer-mobile.png);
    background-repeat: no-repeat;
    background-size: 100%;
    /* height: 423px; */
    padding-top: 70.69%;
    width: 100%;
    /* position: absolute; */
    /* bottom: 0px; */
}
.viernesverde30 .viernes-content {
    min-height: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    /* padding-bottom: 423px; */
    padding-left: 34px;
    padding-right: 34px;
    z-index: 100;
    position: relative;
    color: white;

}
.viernesverde30 .viernes-content .viernes-content-header {
    display: flex;
    flex-direction: column;
    align-items: center;
}



.viernesverde30 .viernes-content .viernes-titulo {
    width: 100%;
}
.viernesverde30 .viernes-content .viernes-titulo img {
    width: 100%;
}
.viernesverde30 .viernes-content .viernes-main-info {
    text-transform: uppercase;
    font-size: 13px;
    text-align:center;
}
.viernesverde30 .viernes-content .viernes-main-info p {
    text-transform: none;
    padding-top: 10px;
}
.viernesverde30 .viernes-content .viernes-title {
    font-weight: bold;
    /* color: #B59F5C; */
    font-size: 22px;    
    text-align: center;
    text-transform: uppercase;
    font-family: Rubik Mono One
    /* font-family: Lobster */
}
.viernesverde30 .viernes-content .viernes-title br {
    /* display: none; */
}

.viernesverde30 .viernes-content .viernes-tickets {
    background: url(https://assets.viveloonline.com/sites/viernesverde30/viernes-logo.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 75%;
    margin-top: 20px;
}

.viernesverde30 .viernes-content .viernes-tickets section {
    text-align: center;
    margin-top: 20px;
}
.viernesverde30 .viernes-content .viernes-tickets section label {
    font-weight: bold;
    padding-left: 5px;
    margin-bottom: 0px;
    text-transform: uppercase;
    font-size: 18px;
}

.viernesverde30 .viernes-content .viernes-tickets .ticket {
    font-weight: bold;
    margin-bottom: 20px;
    border: solid 1px;
    margin: 0px 0px 20px 0px;
    padding: 10px 0px;
    border-radius: 5px;
    background: #b59f5c3d;
}
.viernesverde30 .viernes-content .viernes-tickets .ticket .note {
    font-weight: normal;
    font-size: 10px;
}
.viernesverde30 .viernes-content .viernes-tickets .ticket .presale-date {
    font-weight: normal;

}
.viernesverde30 .viernes-content .viernes-tickets .tickets-addtocart {
    padding: 5px 10px !important;
    margin-top: 0px !important;
    margin-bottom: 15px;
    border: none !important;    
    display: flex;
    justify-content: center;
}
.viernesverde30 .viernes-content .viernes-tickets .tickets-addtocart button {
    background: #B59F5C;
    color: white;
    width: 200px !important;
}
.viernesverde30 .viernes-content .viernes-tickets .tickets-total {
    border: solid 1px;
    margin: 0px 5px 20px 5px;
    padding: 10px 0px;
    border-radius: 5px;
    background: #b59f5c21;
}
.viernesverde30 .viernes-content .viernes-tickets .tickets-total .tickets-total-label {
    font-weight: bold;
    font-size: 20px;
}
.viernesverde30 .viernes-content .viernes-tickets .tickets-total .price {
    font-weight: bold;
    font-size: 20px;
}
.viernesverde30 .viernes-content .viernes-tickets .tickets-footer {
    text-align: center;
}

.viernesverde30 .viernes-content .viernes-sol {
    width: 100%;
}
.viernesverde30 .viernes-content .viernes-sol img {
    width: 100%;
}

.viernesverde30 .viernes-content .viernes-planograma {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}
.viernesverde30 .viernes-content .viernes-planograma img {
    width: 100%;
}

/* ********************* DESKTOP ************************** */


@media (min-width: 992px) {
    .viernes-hide-mobile {
        display: block;
    }
    .viernesverde30 .viernes-bg-body {
        background: #676A53 url(https://assets.viveloonline.com/sites/viernesverde30/viernes-bg-body.png) repeat-y;
        background-repeat: repeat-y;
        background-size: 100%;
        border-radius: 65px;
    }
    .viernesverde30 .viernes-bg-header {
        background: url(https://assets.viveloonline.com/sites/viernesverde30/viernes-bg-header.png);        
        background-repeat: no-repeat;
        background-size: 100%;
        padding-top: 51.34%;
    }
    .viernesverde30 .viernes-bg-footer {
        background: url(https://assets.viveloonline.com/sites/viernesverde30/viernes-bg-footer.png);
        background-repeat: no-repeat;
        background-size: 100%;
        padding-top: 65.33%;
    }
    .viernesverde30 .viernes-content {
        font-size: 22px;
    }
    .viernesverde30 .viernes-content .viernes-content-header {        
        flex-direction: row;
        justify-content: space-around;
        width: 80%;
    }
    .viernesverde30 .viernes-content .viernes-titulo {
        /* width: 500px; */
        /* width: 50%; */
    }
    .viernesverde30 .viernes-content .viernes-title {
        font-size: 30px;
        text-transform: uppercase;
        
    }
    .viernesverde30 .viernes-content .viernes-main-info {
        font-size: 18px;
    }    
    .viernesverde30 .viernes-content .viernes-planograma {
        width: 500px;
    }
    
}