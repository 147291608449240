.site-festivalcomedia2020 {
    margin-top: 65px;
}

.site-festivalcomedia2020 .body {
    background: #000a0e url('https://assets.viveloonline.com/shows/festivalcomedia202012/bg.png') no-repeat scroll 50%;
    height: 566px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    
}

.site-festivalcomedia2020 .body {

}
.site-festivalcomedia2020 .body h1 {    
}
.site-festivalcomedia2020 .body h1 img {
    
}
.site-festivalcomedia2020 .body .events {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: nowrap;
}

.site-festivalcomedia2020 .body .events a:hover {
    /* box-shadow: 0px 5px 5px #888888; */
    color: #c5de00;
    text-decoration: none;
}

.site-festivalcomedia2020 .body .events .movie {
    width: 170px;
    color: white;
}
.site-festivalcomedia2020 .body .events .movie .poster {
    width: 170px;
    height: 287px;
}

.site-festivalcomedia2020 .body .events .info {
    color: white;
}
.site-festivalcomedia2020 .body .events a:hover .info  {
    color: #c5de00;
    text-decoration: none;
}

.site-festivalcomedia2020 .body .events  {
    color: white;
}

@media (max-width: 768px) {
    .site-festivalcomedia2020 .body {
        background: #003246 url('https://assets.viveloonline.com/shows/festivalcomedia202012/bg-vertical.png') no-repeat scroll;
        height: auto;
        background-size: 100% auto; 
        overflow: scroll;  
    }
    
    .site-festivalcomedia2020 .body {
    
    }
    .site-festivalcomedia2020 .body h1 {
        height: 260px;
        display: block;
    }
    .site-festivalcomedia2020 .body h1 img {
        display: none;
    }
    
    .site-festivalcomedia2020 .body .events {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;    
    }
    
    .site-festivalcomedia2020 .body .events a:hover {
        /* box-shadow: 0px 5px 5px #888888; */
        color: #c5de00;
        text-decoration: none;
    }
    
    .site-festivalcomedia2020 .body .events .movie {
        width: 170px;
        color: white;
        margin-bottom: 20px;
    }
    .site-festivalcomedia2020 .body .events .movie .poster {
        width: 170px;
        height: 287px;
    }
    
    .site-festivalcomedia2020 .body .events .info {
        color: white;
        margin-top: -40px;
    }
    .site-festivalcomedia2020 .body .events a:hover .info  {
        color: #c5de00;
        text-decoration: none;
    }
    
    .site-festivalcomedia2020 .body .events  {
        color: white;
    }
}




