
.cart {

}

.cart-summary {
    
}
.cart-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    font-size: 1rem;
}

.cart-summary-box {
    border-top: solid 1px #666;
    padding-top: 20px;
    margin-top: 20px;
    width: 100%;
}

.cart-item-group {
    /* background: black;     */
}

.cart-item {
    padding-bottom: 20px;  
    margin-bottom: 20px;
    border-bottom: solid 1px #333;
}

.cart-item-ticket {

}

.cart-item-info {    
    display: grid;
    grid-template-columns: 110px auto;
}
.cart-item-image, .cart-item-image img {
    width: 100px;
    height: 56px;
}

.cart-item-title {
    font-size: 1rem;
}
.cart-item-title a {
    color: white;
    
}

.cart-item-description {    
    font-size: 0.9rem;
}
.cart-item-note {
    font-size: 0.8rem;
}

.cart-item-numbers {    
}

.cart-item-price {    
    font-size: 1.3rem;
}
.cart-item-fee {

    font-size: 1rem;
}

.cart-item-actions {

}

.cart-item-ticketbox { 
    border-top: solid 2px;
    padding-top: 15px;
}
.cart-item-ticketlist {
    font-size: 1.5rem    
}
.cart-item-ticketlist .ticket {
    margin-bottom: 20px;
}
.cart-item-ticketlist .code {
    color: #13b19c;
    padding-right: 10px;
}

.cart-item-actions a {
 
    font-size: 0.8rem;
    color: #cb0f7e;
    text-transform: uppercase;
}

.cart-subtotal {
    padding-bottom: 20px;  
    margin-bottom: 20px;
    border-bottom: solid 1px #666;
}
.cart-subtotal-info {
    font-size: 1rem;
}
.cart-subtotal-numbers {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
}

.cart-total {
    padding-bottom: 20px;  
    margin-bottom: 20px;
    border-bottom: solid 1px #666;
}
.cart-total-info {
    font-size: 1.5rem;
}
.cart-total-numbers {
    font-size: 1.3rem;
}

.cart-summary-actions {
    display: flex;
    justify-content: space-between;
}

.cartTimer { 
    font-size: 24px;
    text-align: left;
    display: inline-block;
}
.timerBanner {
    background: #cb0f7e4f;
    color: white;
    padding: 5px;
    border: solid 1px #cb0f7e;
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.timerBanner .left {
    display: flex;
    justify-content: start;
    align-items: center;
}
.timerBanner .right {
    display: flex;
    justify-content: end;
    align-items: center;
}


@media (min-width: 900px) {
    .cart-item {
        display: grid;
        grid-template-columns: auto 200px;
    }
    .cart-item-info {        
        grid-template-columns: 220px auto;
    }    
    .cart-item-image, .cart-item-image img {
        width: 200px;
        height: 113px;
    }
    .cart-item-title {
        font-size: 1.5rem;
    }
    .cart-item-description {     
        font-size: 1rem;
    }
    .cart-item-numbers {
        text-align: right;
    }
    .cart-subtotal { 
        display: grid;
        grid-template-columns: auto 200px;
    }
    .cart-subtotal-numbers {
        text-align: right;
    }
    .cart-total { 
        display: grid;
        grid-template-columns: auto 200px;
    }
    .cart-total-numbers {
        text-align: right;
    }

}