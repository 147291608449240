
.site {
    
}

.site-india2023 {
    background: #ffffff;
    background: #4E1A2A url('https://assets.viveloonline.com/shows/semanacineindia202301/header background mobile.png') no-repeat fixed center;
    color: black;
    font-family: 'Roboto';
    background-size: cover;
    margin-top: 65px;
}

.site-india2023 .header {    
    
    height: auto;
    padding: 10px;
    border: none;    
}
.site-india2023 .header img {
    width: 100%;    
}
.site-india2023 .title-container {
    display: flex;
    flex: 1;
    background: white;
    border-radius: 10px;
    /* padding: 5px; */
    margin: 10px;
    position: relative;
    margin-top: 50px;
    margin-bottom: 50px;
}
.site-india2023 .title-container-orange {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(231, 169, 137, 0.5) 0%, rgba(231, 169, 137, 0) 100%);
    border-radius: 10px;
}
.site-india2023 .title-container-yellow {
    width: 100%;
    height: 100%;
    padding: 5px;
    background: linear-gradient(211.99deg, rgba(255, 219, 92, 0.5) -3.47%, rgba(202, 182, 77, 0) 55.25%);
    border-radius: 10px;
}
.site-india2023 .title-container .india-logo {
    width: 87px;
    height: 136px;    
}
.site-india2023 .title-container .movie-collage {
    margin-top: 5px;
}
.site-india2023 .title-container .movie-collage .desktop {
    display: none
}
.site-india2023 .title-container .movie-collage .mobile {
    display: block;
}

.site-india2023 .header .title {
    font-family: 'Rufina';
    color: #664521;
    display: flex;  
    /* position: absolute; */
    align-items: flex-end;
    /* top: -30px;     */
}
.site-india2023 .header .title h1 {
    font-size: 28px;
    font-weight: bold;
    padding-left: 5px;
}
.site-india2023 .header .title h2 {
    color: #AB4105;
    font-size: 14px;
    margin: 0px;
    padding-left: 5px;
}

.site-india2023 .body .description {
    background: white;
    color: #3E3E3E;
    font-family: 'Roboto' !important;
    font-size: 20px;
    padding-top: 20px;
    padding-top: 20px;
    margin-bottom: 30px;
}
.site-india2023 .body .description .logos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 20px;
}
.site-india2023 .body>.logos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 20px;
}


.site-india2023 .body {
    text-align: center;
    background: #f7f7f7;
    padding: 20px 10px;
    padding-bottom: 100px;
}
.site-india2023 .body h1 {
    font-family: 'Rufina';
    color: #403434;
    font-size: 24px;
}
.site-india2023 .body .events {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}
.site-india2023 .body .events a {
    color: black;
    text-decoration: none;
}
.site-india2023 .body .events .movie {
    width: 160px;    
    padding: 5px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 3px 7px #888888;
    border-radius: 5px;
    background-color: white;
    margin: 10px 3px;
    
}
.site-india2023 .body .events a:hover .movie {
    box-shadow: 0px 5px 5px #888888;
}
.site-india2023 .body .events .movie .poster {
    
}
.site-india2023 .body .events .movie .poster img {
    width: 100%;
    border-radius: 3px;
}

.site-india2023 .body .events .movie .info {
    display: grid;
    grid-template-columns: auto 30px;
    padding: 3px;
    align-items: center;
}
.site-india2023 .body .events .movie .info .title  {
    text-align: left;
    font-size: 12px;
}
.site-india2023 .body .events .movie .info .genre  {
    text-align: left;
    font-size: 12px;
    color: #c4c4c4;
}
.site-india2023 .body .events .movie .info .month  {
    text-align: center;
    font-size: 10px;
    font-weight: bold;
}
.site-india2023 .body .events .movie .info .date  {
    text-align: center;
    font-size: 22px;
    margin-top: -5px;
    font-weight: 100;
}

/* SHOW */
.site-india2023 .movie-container {
    display: flex;
    flex: 1;
    background: white;
    border-radius: 10px;
    /* padding: 5px; */
    margin: 10px;
    position: relative;
    /* margin-top: 50px; */
    /* margin-bottom: 50px; */
    margin: 10px 10px 0px 10px;    
}
.site-india2023 .movie-container-orange {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(231, 169, 137, 0.5) 0%, rgba(231, 169, 137, 0) 100%);
    border-radius: 10px;
}
.site-india2023 .movie-container-yellow {
    width: 100%;
    height: 100%;
    padding: 0px;
    background: linear-gradient(211.99deg, rgba(255, 219, 92, 0.5) -3.47%, rgba(202, 182, 77, 0) 55.25%);
    border-radius: 10px;
}

.site-india2023 .show-header {
    display: flex;
    justify-content: space-between;
    align-items: start;
    /* background: white; */
    /* margin: 10px 10px 0px 10px;     */
}
.site-india2023 .show-header .festival-title {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.site-india2023 .show-header .festival-title:hover {
    cursor: pointer;
}
.site-india2023 .show-header .india-logo {
    /* flex-grow: 1; */
    width: 70px;
}
.site-india2023 .show-header .india-logo img {
    width: 100%;
}
.site-india2023 .show-header .title {    
    display: block;
    position: initial;
    font-family: 'Rufina';
    padding: 0px 5px 0px 10px;
    color: #403434;
}
.site-india2023 .show-header .title h1 {
    flex-grow: 2;
    font-size: 22px;
    margin: 0px;    
}
.site-india2023 .show-header .title h1 span {
    display: block;
}
.site-india2023 .show-header .title h2 {
    flex-grow: 2;
    font-size: 14px;
    margin: 0px;
}
.site-india2023 .show-header .title h3 {
    font-size: 11px;
}
.site-india2023 .show-header .date-box {
    /* flex-grow: 1; */
    width: 45px;
    border: solid 1px #C4C4C4;
    background: #E2E2E2;
    border-radius: 3px;
    padding-top: 4px;
    padding-bottom: 0px;
}
.site-india2023 .show-header .date-box .month  {
    text-align: center;
    font-size: 12px;
    font-weight: bold;
}
.site-india2023 .show-header .date-box .date  {
    text-align: center;
    font-size: 24px;
    margin-top: -5px;
    font-weight: 100;
}

.site-india2023 .show {
    /* background: white; */
    padding: 10px;
    /* margin: 0px 10px 10px 10px; */
}
.site-india2023 .show .poster {
    width: 110px;
    float: left;
    margin-right: 10px
}
.site-india2023 .show .poster img {
    width: 100%;
}
/* .site-india2023 .show .show-info {
    display: grid;
    grid-template-columns: 110px auto;
} */
.site-india2023 .show .show-info {
    /* padding-left: 10px; */
}

.site-india2023 .show .show-info .info {
    padding: 0px 10px;
}
.site-india2023 .show .show-info .info .title {
    font-size: 24px;
}
.site-india2023 .show .show-info .info .description {
    font-size: 12px;
}
.site-india2023 .show .instructions {
    font-size: 12px;
}

.site-india2023 .show .movie-info1 {
    display: flex;
    flex-flow: row wrap;
    clear: both;
}
.site-india2023 .show .movie-info1>div {
    border-right: solid 1px #C4C4C4;
    padding-left: 10px;
    padding-right: 10px;
    /* width: 20%; */
    margin-bottom: 5px;
}
.site-india2023 .show .movie-info1>div label {
    font-weight: bold;
    font-size: 8px;
    margin-bottom: 0px;
}
.site-india2023 .show .movie-info1>div>div {    
    font-size: 12px;
}

.site-india2023 .show .movie-info2 {
    
}
.site-india2023 .show .movie-info2>div {
    font-size: 10px;
}
.site-india2023 .show .movie-info2>div label {
    padding-right: 10px;
    width: 50px;
}
.site-india2023 .show .movie-info2>div span {
    color: #666;
}
.site-india2023 .show .ticket {
    padding: 5px 0px;
    border: solid 1px B96A16
}

.site-india2023 .show .actions {
    display: flex;
    flex-direction: column;
}
.site-india2023 .show .actions button {
    width: 100%;
    margin-bottom: 5px;
}

.site-india2023 .show .actions .india-tickets {
    background-color: #F18A1C;
    border-color: #B96A16;
}
.site-india2023 .show .actions .india-tickets:hover {
    background-color: #d07a1e;
    border-color: #B96A16;
    
}
.site-india2023 .show .actions .india-tickets:focus {
    background-color: #d07a1e;
    border-color: #B96A16;
    box-shadow: 0 0 0 0.2rem rgb(255 235 213);
}
.india-tickets:not(:disabled):not(.disabled).active, 
.india-tickets:not(:disabled):not(.disabled):active, 
.india-tickets.dropdown-toggle {

}

.site-india2023 .trailer {
    background: black;
    padding: 20px 0px 60px 0px;
}
.site-india2023 .trailer label {
    font-family: 'Rufina';
    color: white;
    font-size: 24px;  
    text-align: center;
    width: 100%;     
}


/* DESKTOP */

@media (min-width: 768px) {
    .site-india2023 {
        padding-top: 30px;
        background-image: url('https://assets.viveloonline.com/shows/semanacineindia202301/header background.png');
    }
    .site-india2023 .header {    
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
    }
    .site-india2023 .title-container {
        background: white;
        /* border-radius: 10px; */
        /* padding: 20px; */
        /* margin: 10px; */
        position: relative;
        /* margin-top: 50px; */
    }
    .site-india2023 .title-container-orange {
    }
    .site-india2023 .title-container-yellow {
        padding: 20px;
    }
    .site-india2023 .title-container .india-logo {
        width: 87px;
        height: 136px;    
    }
    .site-india2023 .title-container .movie-collage {
        margin-top: 20px;
    }
    .site-india2023 .title-container .movie-collage .desktop {
        display: block;
    }
    .site-india2023 .title-container .movie-collage .mobile {
        display: none;
    }
    .site-india2023 .header .poster {
        width: 50%;
    }
    .site-india2023 .header img {
        width: 100%;    
    }
    .site-india2023 .header .title {
        /* padding: 15px 20px 40px 20px; */
    }
    .site-india2023 .header .title h1 {
        margin-left: 10px;
        font-size: 48px;
        
    }
    .site-india2023 .header .title h1 br {
        display: none;
    }
    .site-india2023 .header .title h2 {
        margin-left: 10px;
        font-size: 28px;
        margin-bottom: 15px;
    }
    
    .site-india2023 .body .description {
        font-size: 14px;
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;        
    }
    .site-india2023 .body .description .logos {
        display: flex;
        flex-direction: row;
    }
    .site-india2023 .body>.logos {
        display: flex;
        flex-direction: row;        
    }

    .site-india2023 .body .events {
        justify-content: center;
        /* display: grid;
        grid-template-columns: 33% 33% 33%;        
        justify-items: center; */
        max-width: 980px;
    }

    .site-india2023 .body .events .movie {
        width: 240px;    
        padding: 15px;
        display: flex;
        flex-direction: column;        
        margin: 20px 38px;
    }
    .site-india2023 .body .events .movie .info .title {        
        font-size: 16px;
    }

    /* SHOW  */    
    .site-india2023 .movie-container {
        max-width: 980px;
        margin-left: auto;
        margin-right: auto;
    }    
    .site-india2023 .movie-container-orange {
    }
    .site-india2023 .movie-container-yellow {
        padding: 5px;
    }
    .site-india2023 .show-header {
        max-width: 980px;
        /* background: none; */
        margin-left: auto;
        margin-right: auto;
        /* padding-left: 10px;
        padding-right: 10px; */
        padding-top: 10px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    .site-india2023 .show-header .india-logo {        
        width: 70px;
    }

    .site-india2023 .show-header .title {        
        padding: 0px 5px 0px 0px;        
    }
    .site-india2023 .show-header .title h1 {
       font-size: 35px;
    }
    .site-india2023 .show-header .title h1 span {
        display: inline-block;
    }
    .site-india2023 .show-header .title h2 {
        font-size: 21px;
    }
    .site-india2023 .show-header .date-box {       
        width: 70px;
        padding-top: 10px;
        padding-bottom: 5px;
    }
    .site-india2023 .show-header .date-box .month  {
        font-size: 20px;
    }
    .site-india2023 .show-header .date-box .date  {        
        font-size: 35px;
        margin-top: -15px;
    }

    .site-india2023 .show {
        max-width: 980px;
        /* background: white; */
        /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
        border-radius: 5px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        /* padding: 10px; */
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
        /* margin-top: 30px;         */
    }
    .site-india2023 .show .show-info {    
    }
    .site-india2023 .show .poster {
        width: 225px;
        margin-right: 20px;
    }    
    .site-india2023 .show .show-info .movie-info1 {
        clear: none;
    }
    .site-india2023 .show .show-info .movie-info2 {
        display: grid;
        grid-template-columns: 50% 50%;
    }
    .site-india2023 .show .actions {
        display: flex;
        flex-direction: row;
    }
    .site-india2023 .show .actions button {
        width: 200px;
        margin-right: 10px;
    }

    .site-india2023 .trailer .video-player {
        max-width: 980px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 0;
        height: 400px;
        background-image: none;
    }
    
}