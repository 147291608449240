
.marimbas2021 { 
    color: white;
    background: white;    
    font-family: 'Montserrat' !important;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 25px !important;
}

.marimbas2021 .show-header {
    padding: 0px;
    width: 100%;
    flex-direction: column;
    justify-content: center;
}
.marimbas2021 .show-header .header {
    width: 100%;
}
.marimbas2021 .show-header .header img {
    width: 100%;
}
.marimbas2021 .show-header .message {
    width: 70%;
    display: grid;
    grid-template-columns: 300px auto;
    padding: 50px 100px 50px 100px;
    align-items: center;    
}
.marimbas2021 .show-header .message .guinness img {
    width: 100%;
}
.marimbas2021 .show-header .message .text img {
    width: 100%;
}

.marimbas2021 .show-header .fecha {
    width: 700px;
}
.marimbas2021 .show-header .fecha img {
    width: 100%;
}

.marimbas2021 .show-footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.marimbas2021 .show-footer .logos {
    width: 70%;    
}
.marimbas2021 .show-footer .logos img {
    width: 100%;
}
.marimbas2021 .show-footer .footer {
    width: 100%;
}
.marimbas2021 .show-footer .footer img {
    width: 100%;
}

.marimbas2021 .show-content {
    margin: 100px;
    border: solid 3px #007130;
    border-radius: 10px;
    overflow: hidden;
}


.marimbas2021 .desktop {    
    display: flex;
}
.marimbas2021 .mobile {
    width: 100%;
    display: none;
}

.marimbas2021 .show-player {
    background: black;
    color: white;
}

@media (max-width: 900px) {
    .marimbas2021 .desktop {    
        display: none;
    }
    .marimbas2021 .mobile {        
        display: flex;
    }

    .marimbas2021 .show-header .message {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0px;
        align-items: center;
    }
    .marimbas2021 .show-header .fecha {
        width: 100%;
    }

    .marimbas2021 .show-content {
        margin: 20px 0px 20px 0px;
        border: none;
        border-radius: 0px;

    }

    .marimbas2021 .show-footer .logos {
        width: 100%;
        padding-bottom: 40px;    
    }
}
  
  

