
.conviviodorada .show-header {
  background: #304076 url('https://assets.viveloonline.com/shows/5fd1a3b32d682cbc40598f80/player-header-background.png') repeat-x;
  color: white;
  border-bottom: solid 3px white;
  padding: 0px 20px 0px 0px;
}
.conviviodorada .show-header .tema {
  width: 499px;
  height: 80px;
}
.conviviodorada .show-header .tema img {
  width: 100%;
  height: 100%;
}
.conviviodorada .show-header .tema img.desktop {
  display: inline-block;
}
.conviviodorada .show-header .tema img.mobile {
  display: none;
}

.conviviodorada .brand-footer {
  background: #070e42 url('https://assets.viveloonline.com/shows/5fd1a3b32d682cbc40598f80/footer-background.jpg') no-repeat;  
  background-size: 100%;
  background-position: center;
  height: 350px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
.conviviodorada .invencible-footer .actions {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 30px;
  font-family: 'Montserrat';

}
.conviviodorada .invencible-footer .actions a {
  padding: 5px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-decoration: none;
}
.conviviodorada .invencible-footer .actions a svg {
  height: 50px;
  width: 50px;
}
.conviviodorada .invencible-footer .actions a:hover {
  color: #E1B340;
  cursor: pointer;
}


@media (max-width: 992px) {
  .conviviodorada .show-header {
    background-size: cover;
  }
  .conviviodorada .show-header .tema {
    width: 218px;
    height: 35px;    
  }
  .conviviodorada .show-header .tema img.desktop {
    /* display: none; */
  }
  .conviviodorada .show-header .tema img.mobile {
    /* display: inline-block; */
  }
  .conviviodorada .brand-footer {
    height: 150px;
  }
  .conviviodorada .small-logo {
    width: 56px !important;
    height: 20px !important;
    margin-top: -5px;
  }
}


