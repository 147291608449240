@media all and (min-width: 480px) {
    .Login {
      padding: 60px 0;
    }
  
    .Login form {
      margin: 0 auto;
      max-width: 320px;
    }

    
  }

  .login-footer {
    margin-top: 30px;
    padding-top: 30px;
    border-top: solid 1px white;
  }

  .login-footer a {
    color: white;
    font-weight: bold;
  }