.Home .home-menu {
  margin-top: 30px;
  margin-bottom: 30px;
}
.Home .home-menu .row {
  width: 100%;
}
.Home .home-menu .nav-logo {
  text-align: center;
  margin:0px;
}
@media (max-width: 500px) {
  .Home .home-menu .nav-logo img {
    width: 276px;
    height: 98px;
  }
}
@media (max-width: 768px) {
  .Home .home-menu .nav-logo img {
    width: 276px;
    height: 98px;
    /*width: 350px;    
    height: 124px;*/
  }
}
@media (min-width: 768px) {
  .Home .home-menu .nav-logo img {
    width: 441px;
    height: 156px;
  }
}
.Home .home-menu .nav {
  text-align: center;
  margin-top: 30px;
}
.Home .home-menu .nav-item {
  color: white;
  margin-left: 15px;
  margin-right: 15px;
  text-transform: uppercase;
  font-weight: normal;
  font-family: 'Oswald';
  color: white !important;
}

/*
.Home .shows {
  width:80%;
  margin-left: auto;
  margin-right: auto;
}
.Home .show {
  margin-bottom: 150px;
}
.Home .show-info {
  padding-top: 20px;
}
.Home .show-title {
  margin-top: -15px;
}
.Home .show-date {  
  padding-bottom: 10px;
}
.Home .show-date time {
  /*margin-left: auto;*
}
.Home .show-actions button {
  /*margin-left: auto;*
}


.Home .shows h4 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    overflow: hidden;
    line-height: 1.5;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .Home .shows p {
    color: #666;
  }
  .Home .shows .list-group-item {
      background-color: black;
  }
  */

  .Home .show {
    border: solid 2px #666;
    margin-bottom: 50px;
    width: 856px;
    min-height: 485px;
    margin-left: auto;
    margin-right: auto;
  }
  .Home .show:hover {
    cursor: pointer;
  }

  .Home .show .background {
    background: url("http://assets.viveloonline.com/shows/${show.id}/main.jpg")  no-repeat top center;
    background-size: cover; 
    width: 856px;
    min-height: 485px;    
    
  }
  .Home .show .layer {
    background-image: linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,0.9));
    width: 100%;
    height: 50%;
    padding: 20px;
    position: relative;
  }
  .Home .show .layer .show-title {
    font-size: 3rem;
    margin:0px;
    text-shadow: 0px 0px 8px #000000;
  }
  .Home .show .layer .show-date {  
    font-size: 1.5rem;
    padding-bottom: 10px;
    text-shadow: 0px 0px 4px #000000;
  }
  .Home .show .layer  .show-date time {
    /*margin-left: auto;*/
  }
  .Home .show .layer  .show-actions button {
    font-size: 1.5rem;
  }
  .Home .show .layer  .show-actions .badge {
    font-size: 1.5rem;
    font-weight: normal;
  }
  .Home .show .layer .live-overlay {
    position: absolute;
    /*font-size: 0.8rem;*/
    right: 10px;
    top: 10px;  
    padding: 2px 8px;
    border-radius: 3px;
    background: rgb(0,0,0,0.5);
  }

  @media (max-width: 992px) {
    .Home .show {    
      width: 690px;
      min-height: 391px;
      margin-bottom: 20px;
    }
    .Home .show .background {
      width: 690px;
      min-height: 391px;
    }
    
    
  }

  @media (max-width: 768px) {
    .Home .show {    
      width: 660px;
      min-height: 374px;
    }
    .Home .show .background {
      width: 660px;
      min-height: 374px;
    }
    .Home .show .layer .show-title {
      font-size: 2rem;
    }
    .Home .show .layer .show-date {  
      font-size: 1rem;
    }
    .Home .show .layer  .show-date time {

    }
    .Home .show .layer  .show-actions button {
      font-size: 1rem;
    }
    .Home .show .layer  .show-actions .badge {
      font-size: 1rem;
    }
    .Home .show .layer .live-overlay {
      font-size: 0.8rem;
    }
  }
  /* iphone 6/7/8 landscape */
  @media (max-width: 667px) {
    .Home .show {    
      width: 510px;
      min-height: 289px;
    }
    .Home .show .background {
      width: 510px;
      min-height: 289px;
    }
  }
  @media (max-width: 576px) {
    .Home .show {    
      width: 354px;
      min-height: 200px;
    }
    .Home .show .background {
      width: 354px;
      min-height: 200px;
    }
  }
  @media (max-width: 375px) {
    .Home .show {    
      width: 277px;
      min-height: 157px;
    }
    .Home .show .background {
      width: 277px;
      min-height: 157px;
    }
  }
  @media (max-width: 320px) {
    .Home .show {    
      width: 250px;
      min-height: 142px;
      margin-bottom: 20px;
    }
    .Home .show .background {
      width: 250px;
      min-height: 142px;
    }
  }

  
  .Home .soon {
    border: solid 2px #666;
    margin-bottom: 50px;
    width: 856px;
    min-height: 485px;
    margin-left: auto;
    margin-right: auto;

    background: url("/img/mas-eventos.png")  no-repeat top center;
    background-size: cover; 
    width: 856px;
    min-height: 300px
  }

  @media (max-width: 992px) {
    .Home .soon {    
      width: 690px;
      min-height: 241px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 768px) {
    .Home .soon {    
      width: 660px;
      min-height: 231px;
    }        
  }
  /* iphone 6/7/8 landscape */
  @media (max-width: 667px) {
    .Home .soon {    
      width: 510px;
      min-height: 178px;
    }   
  }
  @media (max-width: 576px) {
    .Home .soon {    
      width: 354px;
      min-height: 124px;
    }    
  }
  @media (max-width: 375px) {
    .Home .soon {    
      width: 277px;
      min-height: 97px;
    }    
  }
  @media (max-width: 320px) {
    .Home .soon {    
      width: 250px;
      min-height: 87px;
      margin-bottom: 20px;
    }    
  }


.billboard {
  position: relative;
}

.billboard-info {
  position: absolute;
  /* bottom: 70px; */
  /* left: 57px; */
  /* z-index: 99999; */
  width: 600px;
  /* top: 70px; */
}
.billboard-info-top {

}
.billboard-info-middle {
  top: 50%;  
  width: 550px;
}
.billboard-info-bottom {
  bottom: 0px;
}
.billboard-info-title {
  font-weight: bold;
  font-size: 2rem;
  text-shadow: 0px 1px 5px rgba(0, 0, 0, 1);
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.billboard-info-summary {
  text-shadow: 0px 1px 5px rgba(0, 0, 0, 1);
  padding-bottom: 10px;
}
.billboard-info-actions {
  display: flex;
  flex-direction: row;
}
.billboard-info-actions a {
  margin-right: 10px;
}
.billboard-info-actions a button.btn-light {
  color: black;
  font-weight: bold;
}
.billboard-info-actions a button.btn-dark {
  font-weight: bold;
}

.show-carousel {
 display: flex;
 justify-content: center;  
 margin-bottom: 30px;
}
@media (max-width: 992px) {
  .show-carousel {
    display: none;
  }
}
.show-carousel .carousel {
  width: 735px;
}
.show-carousel .carousel .carousel-item {
  width: 735px;
  height: 418px;
}
.show-carousel .carousel .carousel-item:hover {
  cursor: pointer;
}
.show-carousel .carousel .carousel-item img {
  width: 735px;
  height: 418px;
}

.show-card-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 30px;
}
.show-card-list a {
  color: white;
  text-decoration: none;
}
.show-card {
  width: 345px;
  /* height: 345px; */
  height: 194px;
  background-color: rgb(0,0,0,.3); 
  display: flex;
  flex-direction: column;
  margin: 5px;
  border: solid 1px #333;
  position: relative;
  overflow: hidden;
}
.show-card:hover {
  cursor: pointer;
  background-color: rgb(0 0 0);
  /* box-shadow: 0px 0px 8px #807e7e; */
}

.show-card-image {
  width: 345px;
  height: 194px;
}
.show-card-image img {
  width: 345px;
  height: 194px;
}

.show-card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
  width: 344px;
  height: 194px;
}
.show-card-overlay:hover {
  background: rgb(0,0,0,0.85);
}

.show-card-info {
  /* height: 116px; */
  height: 163px;
  display: grid;
  grid-template-columns: 60px auto;
  /* flex-direction: row;
  justify-content: space-between; */
}
.show-card-left {  
  text-align: center;  
}
.show-card-right {
  display: none;  
}
.show-card-overlay:hover .show-card-right {
  display: block;
}
.show-card-footer {
  height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* background: #000000e0; */
  padding-top: 4px;
}

.show-card-day {
  font-size: 24px;
  color: #5bc9b4;
  text-shadow: 0px 1px 5px rgba(0, 0, 0, 1);
  padding-top: 5px;
  font-weight: bold;
  background: rgb(0,0,0,0.6);
}
.show-card-month {
  text-transform: uppercase;
  font-size: 14px;
  text-shadow: 0px 1px 5px rgba(0, 0, 0, 1);
  background: rgb(0,0,0,0.6);
}
.show-card-hour {
  font-size: 12px;
  text-shadow: 0px 1px 5px rgba(0, 0, 0, 1);
  background: rgb(0,0,0,0.6);
  padding-bottom: 10px;
  border-bottom-right-radius: 10px;
}

.show-card-title {
  font-size: 1.3rem;
  text-shadow: 0px 1px 5px rgba(0, 0, 0, 1);
  padding-top: 8px;
  font-weight: bold;
}

.show-card-genre {
  padding-left: 20px;
  text-shadow: 0px 1px 5px rgba(0, 0, 0, 1);
}
.show-card-footer .live {
  background: black;
  padding: 3px 6px 3px 6px;
  /* background: #007bff; */
  border-radius: 22px;
  font-size: 9px !important;
  line-height: 12px;
  text-transform: uppercase;
  font-weight: bold;
  height: 20px;
  margin-top: 2px;
  border: solid 1px #666;  
}
.show-card-footer .vod {
  background: black;
  padding: 3px 6px 3px 6px;
  /* background: #007bff; */
  border-radius: 22px;
  font-size: 9px !important;
  line-height: 12px;
  text-transform: uppercase;
  font-weight: bold;
  height: 20px;
  margin-top: 2px;
  border: solid 1px #666;  
}

.show-card-type {
  background: #232323;
  padding: 5px 10px;
  color: white;
  text-shadow: 0px 1px 5px rgba(0, 0, 0, 1);
}

.show-card-venue {
  padding-right: 10px;  
}

.show-carousel {
  position: relative;
  /* z-index: -99; */
}
.show-carousel .overlay {
  
}
.billboard {
  width: 100%;
  height: 40vw;  
  background-position: center center;
  background-size: cover;  
}

.billboard-background {
  width: 100%;
  height: 56.25vw;  
  background-position: center center;
  background-size: cover;  
  background-image: url("http://assets.viveloonline.com/shows/5eed57a46ba924af375b7178/juevesdetrova-20200626-portada.png");
  z-index: -1;
  position: absolute;
  top:0;
  left:0;
}
.billboard-background .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(20,20,20,1) 5%, rgba(0,0,0,0) 40%);
  /* z-index: 99; */
}

.billboard img {
  background-position: center center;
  background-size: cover;
}

.show-card-list {
  justify-content: left;
}

.show-card-group {  
  /* padding: 0px 50px;   */
}
.show-card-group-title {
  font-size: 1.3rem;
  padding-left: 8px;
  font-weight: bold;
  text-shadow: 0px 1px 5px rgba(0, 0, 0, 1);
  padding-top: 80px;
}



@media (max-width: 992px) {
  .billboard {
    width: 100%;
    height: 80vw;
    padding: 0 20px 40px 20px;
  }
  .billboard-info {
    width: 90%;
    top: 70px;
  }
  .billboard-info-top {

  }
  .billboard-info-middle {
    top: unset;    
  }
  .billboard-info-bottom {
    bottom: unset;
  }
  .billboard-background {
    /* background: none !important; */
  }
  .show-carousel {
    display: block;
  }
  .show-card-list {
    justify-content: center;  
  }
  .show-card-group-title {
    text-align: center;
  }
  .show-card-group {
    margin-top: 55px !important;
  }
  .show-card {      
    height: 345px;
  }
  .show-card-info {
    height: 116px;
    background: black;
  }
  .show-card-overlay {
    position: initial;
  }
  .show-card-right {
    display: block;
  }
}