
.venue_map_selection {
    background: white;
    color: black;
    padding: 10px;
    width: 100%;
}

.venue_map_image {
    margin-top: 10px;
    width: 100%;
}
.venue_map_image {
    width: 100%;
}

.venue_map {
    padding: 20px 10px;
    background: white;
    display: flex;
    justify-content: left;
    align-items: center;
    overflow: auto;
}

.venue_map .venue_section {    
    padding: 20px 40px 20px 20px;
    font-size: 14px;
    flex-grow: 1;
    justify-content: center;
    border: solid 1px #eee;
    border-radius: 5px;
}
.venue_map .seats {
    display: grid;
    gap: 4px;    
}

.venue_map .table {
    display: grid;
    grid-template-columns: 25px 20px 40px 20px 25px;
    column-gap: 4px;    
}

.venue_map .venue_section .numbering {
    font-size: 8px;
    font-weight: bold;
    width: 25px;
    min-width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    color: #666;
}
.venue_map .venue_section .seat_canva {
    /* background-color: #E0DEDE; */
    padding: 2px;
    margin: 0px;
}

.venue_map .venue_section .seat {
    border: solid 1px;
    background-color: #C9167E;    
    border-color: #9C0E60;    
    width: 20px;
    height: 20px;
    border-radius: 10px;
    cursor: pointer;
}
.venue_map .venue_section .seat.single {
    grid-column: span 4;
    margin-left: 33px;
    margin-top: 3px;
    margin-bottom: 3px;
}

.venue_map .venue_section .seat.readonly {
    cursor: default;
}
.venue_map .venue_section .seat.readonly:hover {
    background-color: #D9D9D9;    
    border-color: #C4C4C4;   
}

.venue_map .venue_section .seat.available {

}
.venue_map .venue_section .seat.available:hover {
    background-color: #F03AA4;    
    border-color: #9C0E60;
}
.venue_map .venue_section .seat.reserved {
    background-color: #02D0C6;    
    border-color: #01AFA7;

}
.venue_map .venue_section .seat.sold {
    background-color: #D9D9D9;
    border-color: #C4C4C4;    
    /* background-color: #EA581A;    
    border-color: #BD4411; */
    cursor: not-allowed;
}
.venue_map .venue_section .seat.loading {
    background-color: #EA581A;    
    border-color: #BD4411;
    cursor: wait;
    /* background: linear-gradient(300deg,deepskyblue,darkviolet,blue);
  background-size: 180% 180%;
  animation: gradient-animation 3s ease infinite; */
}

.venue_map .open {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.venue_map .open .selection {
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
}

@media (min-width: 900px) {
    .venue_map {        
        justify-content: center;        
        overflow: auto;    
    }
}

@keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }