
.jwplayer {
  /* border:solid 1px #333; */
  aspect-ratio: 16/9;  
  display: block;
  overflow: hidden;
}

.jwplayer .wait {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;  
}

.jwplayer .wait .cover-image {
  /* position: absolute; */
  width: 100%;  
  background-position: top;
  background-size: contain;
  display: block;
  padding-top: 56.25%;  
  /* padding: 100%; */
}
.jwplayer .wait .cover-block {  
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.jwplayer .wait .cover-card {  
  width: 80%;
  height: 80%; 
  /* margin: 50px;  */
  backdrop-filter: blur(8px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(44, 44, 44, 0.75);
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
  color: white;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.jwplayer .wait .cover-title {
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.jwplayer .wait .cover-time {
  color: white;
  font-size: 12px;
  text-align: center;
}

.jwplayer .wait .cover-message {
  color: white;
  font-size: 16px;
  text-align: center;
}
.jwplayer .wait .cover-footnote {
  color: white;
  font-size: 11px;
  text-align: center;
}


.jwplayer .player {
  width: 100%;
  height: 100%;
}