
.order-list {

}
.order-list a {
  text-decoration: none;
  color: white;
}
.order-list a:hover, .order-list .order:hover {
  background: #333;
}

.order-list .order {
  padding: 20px;
  margin-bottom: 20px;
  border-bottom:solid 1px;
}
.order-list .order .column {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.order-list .order .column label {
  font-weight: bold;
  margin-bottom: 0px;
}


.order-title {
  font-size: 2rem;
}
.order-subtitle {
  /* font-family: 'Roboto', sans-serif; */
}

.order-info {
  /* font-family: 'Roboto', sans-serif; */
}

.order-ticketCode {
  font-family: fixed;
  font-size: 1rem;
}
.order-ticketCode a {
  font-size: 0.8rem;
  font-weight: bold;
  color: #0ecbb2;
}

@media (min-width: 900px) {
  .order-list .order {
    display: grid;
    grid-template-columns: 200px 100px auto 250px;
    text-align: left;
  }

}