
.teleton2021 { 
    color: black;
    background: white;
    font-family: 'Montserrat' !important;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 15px !important;
}

.teleton2021 .header .desktop {
    display: none;
}
.teleton2021 .header .desktop img {
    width: 100%;    
}

.teleton2021 .header .mobile {
    display: block;
}
.teleton2021 .header .mobile img {
    width: 100%;
}

.teleton2021 .body .compra {
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.teleton2021 .body .compra img {
    width: 100%;    
}
.teleton2021 .body .compra .online,
.teleton2021 .body .compra .agencia,
.teleton2021 .body .compra .descarga {
    padding-bottom: 50px;
    text-align: center;
    /* display: flex; */
    flex-direction: column;
    justify-content: center;

}
.teleton2021 .body .compra .online {
    width: 230px;
}
.teleton2021 .body .compra .agencia {
    width: 290px;
}
.teleton2021 .body .compra .descarga {
    width: 100%;
}
.teleton2021 .body .compra .descarga img {
    width: 320px;    
}
.teleton2021 .body .compra .descarga button {
    padding: 5px 20px;
}
.teleton2021 .body .search {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.teleton2021 .body .search label {
    font-size: 20px;
    font-weight: bold;
    color: #00492b;
    padding-right: 5px;
    margin: 0px;
}
.teleton2021 .body .search input {
    font-size: 20px;
    width: 180px;
}
.teleton2021 .body .search button {
}
.teleton2021 .body .results {
    border: solid 2px #01b64f;
    border-radius: 10px;
    margin: 10px;
    padding: 20px;
    font-size: 14px;
}
.teleton2021 .body .results .codes {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.teleton2021 .body .results .codes .code {
    font-size: 20px;
    font-weight: bold;
    color: #00492b;
}

.teleton2021 button {
    background: #01b64f;
    color: white;
    padding: 5px 35px;
    border: none;
    border-radius: 5px;;
    margin: 5px;
    font-weight: bold;
    font-size: 20px;
}
.teleton2021 button:hover {
    background: #00492b;
}
.teleton2021 a.button {
    background: #01b64f;
    color: white;
    padding: 8px 35px;
    border: none;
    border-radius: 5px;;
    margin: 5px;
    font-weight: bold;
    font-size: 20px;
    text-decoration: none;
}
.teleton2021 a.button:hover {
    background: #00492b;
}
.teleton2021 .body .player {
    background: #d7d7d7;
    padding: 100px 30px;
    text-align: center;    
    display: flex;
        flex-direction: column;
        justify-content: center;
    
}
.teleton2021 .body .player img {
    width: 100%;    
}
.teleton2021 .footer {
    padding: 80px 30px;    
}
.teleton2021 .footer .logos {
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    text-align: center; 
}
.teleton2021 .footer .logos img {
    width: 80%;
}



@media (min-width: 900px) {
    .teleton2021 {
        padding: 0px !important;
        margin-left: 0px;
        margin-right: 0px;
    }

    .teleton2021 .header .desktop {
        display: block;
    }    
    .teleton2021 .header .mobile {
        display: none;
    }

    .teleton2021 .body .compra {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: auto;
        justify-items: center;
        
    }
    .teleton2021 .body .compra .online {        
        width: 300px;
    }
    .teleton2021 .body .compra .agencia {        
        width: 390px;
    }
    .teleton2021 .body .compra .descarga {
        width: 430px;
        grid-column-end: span 2;
    }

    .teleton2021 .body .player {
        
    }
    .teleton2021 .body .player img {
        width: 450px;
    }
       
    .teleton2021 .footer .logos img {
        width: 280px;
    }
}


