
.site-astrodeoro { 
    color: white;
    font-family: 'Montserrat' !important;
    padding-left: 0px;
    padding-right: 0px;
    /* margin-top: 105px !important; */
    background-color: black;    
}

.site-astrodeoro .header .background {
  width: 100%;
  height: 56.25vw;  
  background-position: center center;
  background-size: cover;  
  /* background-image: url("http://assets.viveloonline.com/shows/5eed57a46ba924af375b7178/juevesdetrova-20200626-portada.png"); */
  z-index: -1;
  /* position: absolute; */
  top:0;
  left:0;
}

.site-astrodeoro .show-info {
  margin-top: -200px;
  padding: 0px 100px;
}

.site-astrodeoro .show-info .show-title {
  font-weight: bold
}

.site-astrodeoro .show-info .show-badges {
  font-size: 10px;
  display: flex;
  justify-content: flex-start;
  line-height: 21px;  
}
.site-astrodeoro .show-info .show-year {
  margin-right: 10px;
  line-height: 21px;
  height: 21px;
}
.site-astrodeoro .show-info .show-rating {
  margin-right: 10px;
  padding: 4px 4px 0px 6px;
  border: solid 1px #4B4B4B;
  line-height: 12px;
  height: 21px;
}
.site-astrodeoro .show-info .show-quality {
  margin-right: 10px;
  padding: 4px 4px 0px 6px;
  border: solid 1px #4B4B4B;
  line-height: 12px;
  height: 21px;
}
.site-astrodeoro .show-info .show-description {
  margin-top: 30px;
  /* width: 70%; */
}

.site-astrodeoro .body {
  padding: 20px 100px;  
}

.site-astrodeoro .show-playlist {
  padding: 10px 0px 10px 0px;
  background: black;  
  /* background: #191919; */
  border-top: solid 1px #333333;
}
.site-astrodeoro .show-playlist .show-item {
  /* margin: 20px; */
  padding: 30px 0px 30px 0px;
  display: grid;
  grid-template-columns: 320px auto;
  grid-template-rows: 1.5rem auto;
  border-bottom: solid 1px #333333;
}
.site-astrodeoro .show-playlist .show-item.active {
  background: #333333;
}
.site-astrodeoro .show-playlist .show-item .show-item-image {
  grid-row: 1 / span 2;
  width: 300px;
  height: 170px;
  margin-right: 20px;
  position: relative;
  /* margin-bottom: 20px; */
}
.site-astrodeoro .show-playlist .show-item .show-item-image img {
  width: 300px;
  height: 170px;
}
.site-astrodeoro .show-playlist .show-item .show-item-image .play-overlay {
  width: 100%;
  height: 100%;
  color: white;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-content: center;
}
.site-astrodeoro .show-playlist .show-item .show-item-image .play-overlay a {
  width: 100%;
  height: 100%;
  font-size: 5rem;
  color: rgb(255,255,255,0);
  background: rgb(0,0,0,0);
  cursor: pointer;
}
.site-astrodeoro .show-playlist .show-item .show-item-image .play-overlay a:hover {
  width: 100%;
  height: 100%;  
  /* color: rgb(0,0,0,0.7);
  background: rgb(255,255,255,0.4); */
  color: rgb(255,255,255,1);
  background: rgb(0,0,0,0.7);
}
.site-astrodeoro .show-playlist .show-item .show-item-image .live-overlay {
  /*width: 100%;
  height: 100%;*/
  color: white;
  position: absolute;
  top: 0px;
  right: 0px;
  text-align: right;  
}
.site-astrodeoro .show-playlist .show-item .show-item-image .live-overlay div {
  z-index: 101;
  font-size: 0.8rem;
  margin-right: 10px;
  margin-top: 6px;  
  padding: 2px 5px;
  border-radius: 3px;
  background: rgb(0,0,0,0.5);
}
.site-astrodeoro .show-playlist .show-item .show-item-image .soon-overlay {
  width: 100%;
  height: 100%;
  color: white;
  font-weight: bold;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(0,0,0,0.6);  
}
.site-astrodeoro .show-playlist .show-item .show-item-content {
  padding-left: 20px;
}
.site-astrodeoro .show-playlist .show-item .show-item-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.site-astrodeoro .show-playlist .show-item .show-item-title {
  font-weight: bold;
  margin-bottom: 5px;
}
.site-astrodeoro .show-playlist .show-item .show-item-date {
  font-style: italic;
  font-size: 0.9rem;
}
.site-astrodeoro .show-playlist .show-item .show-item-description {

}


@media (max-width: 768px) {

  .site-astrodeoro {
    margin-top: 50px !important;
  }
  .site-astrodeoro .show-info {
    margin-top: -30px;
    padding: 0px 20px;
  }
  .site-astrodeoro .show-info .show-description {
    margin-top: 30px;
    width: 100%;
  }
  .site-astrodeoro .body {
    padding: 10px 20px;
  }

  .site-astrodeoro .show-playlist {
    padding: 20px 0px 20px 0px; 
  }
  .site-astrodeoro .show-playlist .show-item {    
    padding: 20px 0px;    
    grid-template-columns: 160px auto;
    grid-template-rows: auto;
  }
  .site-astrodeoro .show-playlist .show-item.active {
    background: #333333;
  }
  .site-astrodeoro .show-playlist .show-item .show-item-image {
    grid-row: 1 / 1;
    width: 150px;
    height: 85px;
    margin-right: 10px;    
    position: relative;
    /* margin-bottom: 20px; */
  }
  .site-astrodeoro .show-playlist .show-item .show-item-image img {
    width: 150px;
    height: 85px;
  }
  .site-astrodeoro .show-playlist .show-item .show-item-image .play-overlay a {
    font-size: 2.5rem;
    color: rgb(255,255,255,1);
    background: rgb(0,0,0,0.4);
  }
  .site-astrodeoro .show-playlist .show-item .show-item-image .live-overlay div {
    margin-right: 0px;
    margin-top: 0px;  
  }
  .site-astrodeoro .show-playlist .show-item .show-item-info {
    display: block;
  }
  .site-astrodeoro .show-playlist .show-item .show-item-description {
    grid-column: 1 / span 2;
    padding-top: 10px;
  }


}



/* PLAYER */

.beatles2021.player {
    background: #EB4900 !important;
    background-image: none;
    margin-top: 0px !important;
}
.beatles2021 .show-header {
    display: block;
    padding: 0;
    background: #EB4900;
    color: white;        
    background: #EB4900 url('https://assets.viveloonline.com/shows/608f26b10a8a890f8c8ca88d/BeatlesFondo.png') no-repeat 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;    
    padding: 10px 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }   
  .beatles2021 .show-header .tema {
    width: 140px;    
    /* height: 80px; */
  }
  .beatles2021 .show-header .tema img {
    width: 100%;
    height: 100%;
  }
  .beatles2021 .show-header .tema img.desktop {
    display: inline-block;    
  }
  .beatles2021 .show-header .tema img.mobile {
    display: none;
  }
  .beatles2021 .video-player {
      background-image: url('https://assets.viveloonline.com/shows/608f26b10a8a890f8c8ca88d/BeatlesBN.png');
  }

  .beatles2021 .show-footer {
    background-color: #EB4900;    
    background: #EB4900 url('https://assets.viveloonline.com/shows/608f26b10a8a890f8c8ca88d/BeatlesFondo.png') no-repeat;    
    /* background: url('https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/sparks.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%; */
    background-size: cover;
    
  }
  .beatles2021 .show-footer .sponsors {
    display: grid;
    grid-template-columns: 220px 220px 220px 220px;
    align-items: center;
    /* grid-template-rows: 200px; */
    width: 880px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
    padding-bottom: 50px;
}
.beatles2021 .show-footer .sponsors img {
    width: 175px;
}  
.beatles2021 .show-footer .collage {
    text-align: center;
    padding-bottom: 100px;
}
.beatles2021 .show-footer .collage img {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}  

.beatles2021 .contact-box {
    background: #cb0f7e;
    color: white;
    padding: 20px;
    text-align: center;
}
.beatles2021 .contact-box img {
    width: 150px;
    padding-bottom: 10px;
}

.beatles2021 .help-box {
    padding: 30px;
    background: black;
}

  
  @media (max-width: 992px) {
    .beatles2021 .show-header {
        height: 45px;        
        padding: 5px;
        padding-right: 10px;
        /* padding-top: 10px; */
        /* position: fixed; */
        top:0px;
        left:0px;
        right:0px;
    }
    .beatles2021 .show-header .tema {
      width: 62px;
      height: 35px;    
    }
    .beatles2021 .show-header .tema img.desktop {
      display: none;
    }
    .beatles2021 .show-header .tema img.mobile {
      display: inline-block;
    }
    .beatles2021 .show-player {
        /* position: fixed;
        top: 45px;
        z-index: 9999; */
    }
    .beatles2021 .show-footer {
       /* margin-top: 280px; */
    }
    .beatles2021 .show-footer .sponsors {
      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-rows: 120px;
      width: 100%;
      align-items: center;      
      margin-left: auto;
      margin-right: auto;
    }
    .beatles2021 .show-footer .sponsors img {
        width: 120px;
        justify-self: center;
    } 
    .beatles2021 .show-interactions {
        /* margin-top: 260px; */
    }
    .beatles2021 .brand-footer {
      
    }
    .beatles2021 .brand-footer img {
        width: 100%;
    }
    .beatles2021 .brand-footer img.desktop {
        display: none;
      }
      .beatles2021 .brand-footer img.mobile {
        display: inline-block;
      }
    .beatles2021 .small-logo {
      width: 56px !important;
      height: 20px !important;
      margin-top: -4px;
    }
    .beatles2021 .show-header .social-icons {
        position: fixed;
        bottom: 10px;
        z-index: 100;
        background: #1d1d1d7d
    }
  }
  
  
  

