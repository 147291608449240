.App {
  text-align: center;
}
#helpme {
  display: inline-block;
  position: fixed;
  bottom: 20px;
  right: 84px;
  /* background: url('/img/messenger.png') no-repeat; */
  background: url('/img/help2.png') no-repeat;
  width: 48px;
  height: 48px;
  background-size: contain;
}

#helpme:hover {
  width: 56px;
  height: 56px;
}
#helpme:hover:before {
  content: "Ayuda";
  color: white;
  position: relative;
  top: -48px;
  left: 8px;
  line-height: 56px;
  text-align:center;
  width: 56px;
}
@media (max-width: 768px) {
  #helpme {
      width: 40px !important;
      height: 40px !important;
      bottom: 24px !important;
      right: 80px !important;
  }
  #helpme:hover {
    width: 42px;
    height: 42px;
  }
}
/*
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




@font-face {
  font-family: Oswald;
  src: url('Oswald-VariableFont_wght.tff');
}

*/

body {
  background: #141414;
  color: white;
  /* font-family: 'Oswald' !important; */
  font-family: 'Montserrat' !important;
  font-size: 1rem;
  /* background: url(/img/bg01.png) no-repeat center center fixed;  */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

a {
  color: white;
  text-decoration: underline;
}

a:hover, a:active { 
  color: white;
  text-decoration: none;
}

a.btn {
  text-decoration: none;
}

.main-container {
  padding-left: 50px;
  padding-right: 50px;    
}

.main-menu {
/* 
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: solid 1px white;    
  height: 86px; */
  position: fixed;
  top:0;
  z-index: 999;
  /* background-color: black; */
  width: 100%;
  padding: 0px 10px;
  background: linear-gradient(180deg, rgba(0,0,0,1) 32%, rgba(0,0,0,0) 92%);
}

.main-menu a {
  text-decoration: none;
}

.navbar-dark {
  /* background-color: black; */
  background-color: transparent !important;
  padding-bottom: 10px;
  /* margin-bottom: 10px; */
  padding-left: 0px;
  padding-right: 0px;
  /*border-bottom: solid 1px white;*/
}
.bg-dark {
  /* background-color: black !important; */
  
}
.navbar-brand {
  width: 91px;
  height: 45px;
  margin-right: 0px;
  padding-left: 10px;
  
}
.logo {
  text-align: left;
  background-image: url('/img/vivelo-logo2.png');
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 91px 20px;
  width: 91px;
  height: 20px;
  content: ' ';
  line-height: 20px;
  display: inline-block;
}
@media (min-width: 992px) {
  body {
    /* background: url(/img/bg01.png) no-repeat center center fixed;  */
  }
  .navbar-brand {
    /* width: 155px;
    height: 55px;
    margin-right: 1rem; */
    margin-right: 40px;
    padding-top: 11px;
  }
  .logo {
    /* width: 155px;
    height: 55px;
    background-size: 155px 55px; */
  }
  .main-menu {
    padding: 0px 40px;
  }
  .bg-dark {
    background-color: transparent !important;
  }
  .content {
    padding-left: 50px;
    padding-right: 50px;
  }
  .navbar-nav.icons {
    background-color: transparent !important;
  }
  
}

.navbar-collapse {
  padding-top: 10px;
  padding-left: 15px;
}
.navbar-collapse.collapsing, 
.navbar-collapse.show,
.navbar-collapse.show .navbar-nav {
  background-color: black;
  z-index: 9999999 !important;
}

.navbar-dark .navbar-nav {
  /* text-transform: uppercase; */
  font-weight: normal;    
  font-size: 0.8rem;
  /* font-family: 'Oswald'; */
  color: white !important;
}

.navbar-dark .navbar-nav .highlight1 {
  color: #cb0f7e !important;
}
.navbar-dark .navbar-nav .highlight1.nav-link {
  color: #cb0f7e !important;
}
.navbar-dark .navbar-nav .highlight2 {
  color: #cb0f7e !important;
  font-weight: bold;
}
.navbar-dark .navbar-nav .highlight2.nav-link {
  color: #cb0f7e !important;
  font-weight: bold;
}

/* .navbar-nav.icons {
  flex-direction: row; 
  background-color: black;   
} */
.navbar-nav.icons .icon {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgb(255,255,255,1);
}
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgb(255,255,255,0.75);
}
/* .navbar-nav.icons.right {
  background-color: black;
  width: 100%;
} */



.user {
  /* font-family: 'Oswald'; */
  font-size: 16px;
  text-align: right;
  height: 55px;
  line-height: 55px;    
  color: #585858;
}

.user-icon {
  height: 42px;
  width: 42px;
}

.content {
  padding-top: 15px;  
  padding-bottom: 25px;
  margin-top: 65px;
}
.content-dark {
  /* background-color: rgb(0,0,0,0.7) */
}

.content h1 {
  color: #0ecbb2;
  font-size: 1.8rem;
}
.content .description {
  /* font-family: 'Roboto', sans-serif; */
  padding-bottom: 20px;
}



.show-image {    
  /*width: 100%;*/
}
.show-image img {
  border: solid 2px #666;
  width: 100%;
  /*margin-left: 0px;
  */
}
.show-image.primary {
  /*width: 100%;*/
}

.show-row {
  margin-bottom: 30px;
}

.show-title {
  /* font-family: 'Oswald','Avenir Heavy'; */
  font-size: 28px;
  text-align: left;
}
.show-title a {
  color: white;
}
.show-date {
  /* font-family: 'Oswald','Helvetica Neue'; */
  font-weight: lighter;
  text-transform: uppercase;
  font-size: 18px;
  text-align: left;

}
.show-date a {
  color: white;
}

.show-title.primary{
  font-size: 40px;
}
.show-date.primary{
  font-size: 20px;
}

.show-venue span {
  font-size: 12px;
  background: rgb(117,101, 49);
  display: inline-block;
  height: 19px;
  border-radius: 22px;
  padding-right: 6px;
  font-weight: bold;
}
.show-venue label {
  margin: 0;
  margin-right: 5px;
  padding: 4px 6px 3px 6px;
  background: #007bff;
  border-radius: 22px;
  font-size: 9px !important;
  line-height: 12px;
  text-transform: uppercase;
  font-weight: bold;
  height: 19px;
}
.show-venue label.virtual {
  background: #0ecbb2;
  color: black;  
}
.show-venue label.presencial {
  background: #ffc107;
  color: black;
}


.tabs {
  padding-bottom: 20px;
}
.tab-option a {
  color: #cb0f7e;
  font-size: 20px;
  text-transform: uppercase;
}
.tab-option.active a {
  color: #fa5504;
}
.tab-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  margin-top: -5px;
}
.data-table label {
  color: #646464;
  margin-bottom: 0px;
  font-size: 0.8em;
}
label.title {
  color: white;
  font-size: 1.3rem;
}
.data-table {
  font-size: 1.5rem;
}
.data-table .row {
  padding-bottom: 20px;
}
.price {
  text-align: right;
}
.note {
  font-size: 12px;
}

.accordion {

}
.card-header {
  background: #252525;
}
.card-body { 
  color: black;
}
.credit-card-input {
  padding-top: 0px;
}



a.big-button {
  width: 90px;
  float: left;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  display: inline-block;
}
a.big-button img {
  width: 88px;
  height: 88px;
}
a.big-button label {
  font-size: 1.2rem;
}
a.big-button:hover label {
  color: white !important;
}

a.big-button.orange label {
  color: #fa5504;
}
a.big-button.yellow label {
  color: #fab805;
}
a.big-button.cyan label {
  color: #05e0fa;
}

.stat {
  width: 90px;
  float: left;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  display: inline-block;
  color: #05e0fa;
}
.stat .number {
  font-size: 1.8rem;
}
.stat img {
  width: 88px;
  height: 88px;
}

.stat label {
  font-size: 1.2rem;
}
.stat.yellow .number {
  color: #fab805;
  font-size: 1.8rem;
}
.stat.yellow label {
  color: #fab805;
  font-size: 1.2rem;
}


.noty_theme__bootstrap-v4.noty_type__error {
  background-color: #bd2130;
  color: white;
  border-color: #ebccd1;
}





.social-footer {
  padding: 1rem 0;
  margin-top: 30px;
  margin-bottom: 10px;
  background: rgb(0,0,0,0.5);
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: justify;
          justify-content: center;
}

.social-footer .social-footer-icons ul {
  margin: 0px;
  padding: 0px;
}
.social-footer .social-footer-icons ul li {
  display: inline;
  padding: 0px 10px;
}

.social-footer .social-footer-icons ul li a {
  font-size: 1.5rem;
  color: white;
  text-decoration: none;
}
.social-footer .social-footer-icons ul li a:hover {
  text-decoration: none;
  color: #11c5ad;
}

.social-footer .social-footer-icons li:last-of-type {
  margin-right: 0;
}

.social-footer .social-footer-icons .fa {
  font-size: 1.3rem;
  color: #fefefe;
}

.social-footer .social-footer-icons .fa:hover {
  color: #4a4a4a;
  transition: color 0.3s ease-in;
}


.modal-content {
  background: #333;
}
.modal-header {
  color: #11c5ad;
  border-color: #6c757d;
}
.modal-header .close {
  color: white;
}

/* .btn-primary {
  background-color: #fa5605;
    border-color: #993100;
}
.btn-primary:hover {
  background-color: #C23F00;
    border-color: #993100;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #C23F00;
  border-color: #993100;
}
.btn-primary.focus, .btn-primary:focus {
  color: #fff;
  background-color: #C23F00;
  border-color: #993100;
  box-shadow: 0 0 0 0.2rem rgba(153,49,0,.5);
}          */

.btn-primary {
  background-color: #0069d9;
    border-color: #0062cc;
}
.btn-primary:hover {
  background-color: #316DA7;
    border-color: #0062cc;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #316DA7;
  border-color: #0062cc;
}
.btn-primary.focus, .btn-primary:focus {
color: #fff;
  background-color: #316DA7;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(15,90,162,.5);
}         


.btn-success {
  background-color: #03A86E;
    border-color: #006642;
}
.btn-success:hover {
  background-color: #008254;
    border-color: #006642;
}
.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show>.btn-success.dropdown-toggle {
  color: #fff;
  background-color: #008254;
  border-color: #006642;
}
.btn-success.focus, .btn-success:focus {
  color: #fff;
  background-color: #008254;
  border-color: #006642;
  box-shadow: 0 0 0 0.2rem rgba(0, 102, 66,.5);
}         

.password-recover {
  font-family: 'Montserrat';
  font-size: 0.8rem;
  color: white;
}

@media (min-width: 992px) {
  .container {
    max-width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
}