
.poeta .show-header {
  background: #310057 url('https://assets.viveloonline.com/shows/5fabf2947094a9f522057ae3/InvencibleBG.png') no-repeat fixed center;
  color: white;
  border-bottom: solid 3px #E1B340;  
  /* font-family: 'Montserrat'; */
  /* display: flex;
  flex-direction: row;
  justify-content: space-between;  
  align-items: center;
  padding: 10px 10px;
  padding-top: 10px;
  padding-bottom: 10px; */
}
.poeta .show-header .tema {
  width: 100px;
  height: 63px;
}
.poeta .show-header .tema img {
  width: 100%;
  height: 100%;
}

.poeta .invencible-footer {
  background: #310057 url('https://assets.viveloonline.com/shows/5fabf2947094a9f522057ae3/InvencibleBG.png') no-repeat fixed center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.poeta .invencible-footer .actions {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 30px;
  font-family: 'Montserrat';

}
.poeta .invencible-footer .actions a {
  padding: 5px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-decoration: none;
}
.poeta .invencible-footer .actions a svg {
  height: 50px;
  width: 50px;
}
.poeta .invencible-footer .actions a:hover {
  color: #E1B340;
  cursor: pointer;
}


@media (max-width: 992px) {
  .poeta .show-header .tema {
    width: 48px;
    height: 30px;
  }
}


