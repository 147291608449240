
.site-invencible {
    background: #310057 url('https://assets.viveloonline.com/shows/5fabf2947094a9f522057ae3/InvencibleBG.png') no-repeat fixed center;
    color: white;
    font-family: 'Montserrat';
    margin-top: 65px;
}
.invencible-poster {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
}
.invencible-poster img {
    /* width: 80%; */
    width: 256px;
    height: 358px;
}

.invencible-registration {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}
.invencible-registration .date img {
    width: 180px;
    padding-bottom: 10px;
}
.invencible-registration .hour {    
    padding-bottom: 10px;
    text-transform: uppercase;
    font-size: 0.8rem;
}
.invencible-registration .actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0 20px 30px 20px;
}
.invencible-registration .actions button {
    border: solid 2px white;
    color: white;
    font-size: 0.8rem;
    font-weight: bold;
    /* flex-grow: 1; */
}
.invencible-registration .actions button:hover {
    background-color: rgb(255,255,255,0.2);    
    
}
.invencible-registration .actions button:focus {
    background-color: rgb(255,255,255,0.2);    
    box-shadow: 0 0 0 0.2rem rgb(255 255, 255);
}

.invencible-registration .actions button:not(:disabled):not(.disabled).active, 
.invencible-registration .actions button:not(:disabled):not(.disabled):active, 
.invencible-registration .actions button.dropdown-toggle {
    background-color: rgb(255,255,255,1);    
    box-shadow: 0 0 0 0.2rem rgb(255 255, 255);
    color: #310057;
}

.invencible-description {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    background: black;
    color: white;
    font-size: 0.9rem;
    padding: 30px 20px 30px 20px;    
}

.invencible-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
    /* padding: 30px; */
}
.invencible-footer img {
    /* width: 100%; */
    width: 272px;
    height: 37px;
}


@media (min-width: 900px) {
    .site-invencible {
        display: grid;
        grid-template-columns: 40% auto;
        grid-template-rows: 1 1 1;
        padding-top: 40px;
        padding-bottom: 40px;
        padding: 40px 60px 40px 0px;
    }
    .invencible-poster {
        grid-row: span 3;
        align-items: center;        
    }
    .invencible-poster img {
        width: 370px;
        height: 517px;
    }
    .invencible-registration {
        padding-top: 40px;
    }
    .invencible-registration .date img {
        width: 230px;
        padding-bottom: 10px;
    }
    .invencible-registration .actions button {
        width: 250px;
    }
    .invencible-description {
        background: none;
        padding: 10px 20px 10px 20px; 
    }
    .invencible-description p {
        max-width: 620px;
    }
    .invencible-footer {
        border-top: solid 3px #E1B340;
        padding-bottom: 40px;
    }
    .invencible-footer img {
        /* width: 100%; */
        width: 480px;
        height: 65px;
    }
}