
.carasdelamor { 
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(75,75,75,1) 20%);
    font-family: 'Montserrat' !important;    
}

.carasdelamor h1 {
    font-family: 'Montserrat';
    color: white !important;
}

.carasdelamor .main_image {
    width: 100%;
    margin-bottom: 50px;
}

.carasdelamor .show-description {
    font-family: 'Montserrat';
}

.carasdelamor .ticket-box {
    width: 300px;
    text-align: left;
    margin: 0px;
    padding: 0px;
    margin-top: 40px;
}


.carasdelamor .biobox {    
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 10px;
}

.carasdelamor .biobox .bio {    
    font-family: "Montserrat";
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    padding: 30px;
    margin: 10px;
    position: relative;
}

.carasdelamor .biobox .bio .biopic {
    width: 120px;
    height: 170px;
}

.carasdelamor .biobox .bio h1 {
    color: white;
}

.carasdelamor .biobox .bio p {
}

.carasdelamor .biobox .bio .social {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 1.5rem;
    position: absolute;
    top: 25px;
    right: 20px;
}
.carasdelamor .biobox .bio .social a {
    padding:0px 5px;
}

@media (max-width: 900px) {
    .carasdelamor .biobox {
        display: block;
    }
}