.Show {
 /* margin-bottom: 150px;*/
}
.Show .show-image {
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}
.Show .show-title {
  /*margin-top: -15px;*/
}
.Show .show-date {
  /* padding-bottom: 10px; */
}
.Show .show-date time {  
}
.Show .show-venue {
  padding-bottom: 10px;
}

.Show .show-description {
  font-family: Arial;
}
.Show .show-actions {
  padding-top: 10px;
}
.Show .show-actions button {
  margin-right: 15px;
  background: transparent;
}
.Show .show-tickets, .Show .show-payment {  
  margin-top: 25px;
  padding-left: 0px;
}
.Show .note {
  padding-bottom: 15px;
}
.Show .show-tickets .tickets-addtocart {
  display: flex;
  justify-content: end;
}
.Show .order-summary {
  font-size: 0.8em;
}
.Show .tickets-button {
  display: none;
}

.Show .quickbuy_link {

}
.Show .quickbuy_link a {
  display: grid;
  grid-template-columns: 50px auto;
  align-items: center;
  font-size: 1rem;
  text-decoration: none;
  border: solid 1px #117a8b;
  background: #17a2b8;
  padding: 10px;
  border-radius: 5px;
}
.Show .quickbuy_link a:hover {
  background: #117a8b;
}
.Show .quickbuy_link a svg {
  font-size: 30px
}


.Show .shows h4 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    overflow: hidden;
    line-height: 1.5;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
.Show .play-overlay {
  width: 100%;
  height: 100%;
  color: white;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 100;
}
.Show .play-overlay a {
  width: 100%;
  height: 100%;
  font-size: 5rem;
  color: rgb(255,255,255,0.7);
  background: rgb(0,0,0,0.4)  
}
.Show .play-overlay a:hover {
  width: 100%;
  height: 100%;
  font-size: 5rem;
  color: rgb(0,0,0,0.7);
  background: rgb(255,255,255,0.4)
}
.Show .play-overlay a.icon {
  
}

.Show .live-overlay {
  /*width: 100%;
  height: 100%;*/
  color: white;
  position: absolute;
  top: 0px;
  right: 0px;
  text-align: right;  
}
.Show .live-overlay div {
  z-index: 101;
  font-size: 0.8rem;
  margin-right: 10px;
  margin-top: 6px;  
  padding: 2px 5px;
  border-radius: 3px;
  background: rgb(0,0,0,0.5);
}

@media (max-width: 768px) {
  .Show .tickets-button {
    display: inherit;
  }
}

.field-ok, .field-error{
  border: solid 2px white;
}
.field-error {
  border-color: red;
}

.Show a, .Show a:hover {
  color: white;
}