
.seguridadvial { 
    color: #524763;       
    font-family: 'Montserrat' !important;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 25px !important;
    background-color: white;
    /* background: repeat-y url('https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/bg.jpg') center top; */
    background-size: contain;
}

.seguridadvial .main-desktop {
    width: 100%;
    display: none;
}
.seguridadvial .main-desktop img {
    width: 100%;
}
.seguridadvial .main-mobile {
    width: 100%;
    display: block;
}
.seguridadvial .main-mobile img {
    width: 100%;
}

.seguridadvial .separator {
    background: #F4D47B;
    height: 10px;    
}
.seguridadvial .separator.blue {
    background: #5FB3E8;
}
.seguridadvial .separator-line {
    background: #ebebeb;
    height: 1px;
    width: 80%;    
}

.seguridadvial .info {
    background: white;
    color: #524763;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.seguridadvial .info .info-text {
    text-align: center;
    padding: 20px 20px;    
}

.seguridadvial .info-boxes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
}
.seguridadvial .info-boxes .title {
    font-size: 20px;
    font-weight: bold;
    padding: 30px 0px;
}
.seguridadvial .info-box {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 50px;
}

.seguridadvial .info .boxtitle {
    color: #5FB3E8;
    font-weight: bold;
}

.seguridadvial .field-ok {
    border-color: #ced4da;
}
.seguridadvial .field-error {
    border-color: #5FB3E8;
}
.seguridadvial .downloads {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
}
.seguridadvial .downloads img {
    width: 200px;
    height: 200px;
}
.seguridadvial .show-content a {
    color: #5FB3E8;
}
.seguridadvial .phone {    
    display: flex;
    flex-direction: column;
    justify-content: end;
}
.seguridadvial .video {
    width: 100%;   
    padding-bottom: 50px; 
}

.seguridadvial .speakers {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    padding-bottom: 50px;
}
.seguridadvial .speakers .speaker {
    display: flex;
    flex-direction: column;
    align-items: center;     
    text-align: center;
    padding-bottom: 20px;
    /* width: 300px; */
}
.seguridadvial .speakers .speaker img {
    width: 200px;
    height: 200px;    
}
.seguridadvial .speakers .speaker .name {
    font-size: 24px;
    font-weight: bold;
    padding-top: 10px;
}
.seguridadvial .speakers .speaker .role {
    font-size: 22px;
    font-weight: bold;
    font-style: italic;
    color: #5FB3E8;
}

@media (min-width: 900px) {
    .seguridadvial {
        padding: 0px !important;        
        margin-left: 0px;
        margin-right: 0px;
    }
    .seguridadvial .main-desktop {
        display: block;
    }
    .seguridadvial .main-mobile {
        display: none;
    }
    .seguridadvial .info .info-text {
        width: 80%;
        padding: 50px 20px;
    }
    .seguridadvial .info-box {
        display: grid;
        grid-template-columns: 40% 60%;
        width: 80%;
        gap: 20px;
    }
    .seguridadvial .chat-title {
        font-family: 'Roboto';
    }
    .seguridadvial .downloads {
        display: flex;
        flex-direction: column;
    }
    .seguridadvial .speakers {
        display: grid;
        grid-template-columns: 50% 50%;        
        gap: 10px;
    }
    .seguridadvial .speakers .speaker {
        width: 400px;
    }
}


/* PLAYER */

.seguridadvial.player {
    background: white !important;
    background-image: none;
    margin-top: 0px !important;
}
.seguridadvial .show-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: black;
    padding: 5px 20px;
  }    
  .seguridadvial .show-header .header-content {
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;    
    padding: 10px 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .seguridadvial.player .show-header .vivelo-logo {
    width: 91px;
    height: 20px;
  }
  .seguridadvial.player .header-title {
    font-weight: bold;
  }
  .seguridadvial.player .brand {
    width: 100%;
  }
  .seguridadvial.player .brand img {
    width: 100%;
  }
  .seguridadvial.player .logos {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }
  .seguridadvial.player .show-messages {
    color: white;
  }
  .seguridadvial.player .show-ended {
    color: white;
  }
  .seguridadvial.player .show-ended.invalid-code {
    color: #5f5f5f;
  }

  .seguridadvial .video-selector {
      /* background: #1C1C1C; */
      height: 60px;
      display: flex;
      /* justify-content: flex-end; */
      align-items: center;
      padding: 12px;
  }
  .seguridadvial .video-selector .btn.active {
      background: #BC8E45;      
      font-weight: bold;
  }
  .seguridadvial .video-player {
      /* background-image: url('https://assets.viveloonline.com/shows/609339cb6f75e09d25f4d53d/Portada BN.png'); */
  }

  .seguridadvial .show-footer {
    background: white;
  }
  
  
  
  @media (max-width: 992px) {
    .seguridadvial .show-header {
        height: 90px;
        /* position: fixed; */
    }
    .seguridadvial.player .header-title {
        font-size: 14px;
    }
    .seguridadvial .show-header .tema {
      width: 85px;
      /* height: 35px;     */
    }
    .seguridadvial .show-header .tema img.desktop {
      display: none;
    }
    .seguridadvial .show-header .tema img.mobile {
      display: inline-block;
    }
    .seguridadvial .show-header .header-legend {
        width: 50%;
    }
    .seguridadvial .show-header .header-legend img {
        width: 100%;
    }
    .seguridadvial .show-header .contacto {
        font-size: 7px;
        width: 100px;
        padding: 0px;
        /* align-items: center; */
      }
      .seguridadvial .show-header .contacto img {
          width: 15px;
      }
    .seguridadvial .show-player {
        /* position: fixed;
        top: 90px;
        z-index: 9999; */
    }
    .seguridadvial.player .logos {
        flex-direction: column;
        padding: 50px 10px;
    }
    .seguridadvial .show-footer {
       
    }
    
    .seguridadvial .show-footer .footer-logo img {
        width: 200px;
        margin: -20px;
      }
    .seguridadvial .show-footer .sparkBottom {
        padding: 20px;
    }
    .seguridadvial .show-footer .footer-legend {
        font-weight: bold;
        font-size: 18px;        
        padding: 0px 20px 25px 20px;
    }
    .seguridadvial .show-footer .grid-financieros {
      display: grid;
      grid-template-columns: 33% 33% 33%;
      grid-template-rows: 120px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
    .seguridadvial .show-footer .grid-financieros .grid-item label {
        font-size: 8px;
    } 
    .seguridadvial .show-footer .grid-financieros .grid-item img {
        width: 80px;
    } 
    .seguridadvial .show-interactions {
        /* margin-top: 260px; */
    }
    .seguridadvial .brand-footer {
      
    }
    .seguridadvial .brand-footer img {
        width: 100%;
    }
    .seguridadvial .brand-footer img.desktop {
        display: none;
      }
      .seguridadvial .brand-footer img.mobile {
        display: inline-block;
      }
    .seguridadvial .small-logo {
      width: 56px !important;
      height: 20px !important;
      margin-top: -4px;
    }
    .seguridadvial .show-header .social-icons {
        position: fixed;
        bottom: 10px;
        z-index: 100;
        background: #1d1d1d7d
    }
  }
  
  
  

