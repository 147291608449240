
.site-capitanelectrico { 
  color: white;
  font-family: 'Montserrat' !important;
  padding-left: 0px;
  padding-right: 0px;
  /* margin-top: 105px !important; */
  background-color: black;    
}

.site-capitanelectrico .header .background {
display: block;
width: 100%;
height: 56.25vw;  
background-position: center center;
background-size: cover;  
/* background-image: url("http://assets.viveloonline.com/shows/5eed57a46ba924af375b7178/juevesdetrova-20200626-portada.png"); */
z-index: -1;
/* position: absolute; */
top:0;
left:0;
}
.site-capitanelectrico .header .background-mobile {
display: none;
width: 100%;
height: 162.66vw;
background-position: top center;
background-size: cover;  
/* background-image: url("http://assets.viveloonline.com/shows/5eed57a46ba924af375b7178/juevesdetrova-20200626-portada.png"); */
z-index: -1;
/* position: absolute; */
top:0;
left:0;
}

.site-capitanelectrico .channel-info {
margin-top: -200px;
padding: 0px 100px;
}

.site-capitanelectrico .channel-info .show-title {
font-weight: bold
}

.site-capitanelectrico .channel-info .show-badges {
font-size: 10px;
display: flex;
justify-content: flex-start;
line-height: 21px;  
}
.site-capitanelectrico .channel-info .show-year {
margin-right: 10px;
line-height: 21px;
height: 21px;
}
.site-capitanelectrico .channel-info .show-rating {
margin-right: 10px;
padding: 4px 4px 0px 6px;
border: solid 1px #808080;
line-height: 12px;
height: 21px;
}
.site-capitanelectrico .channel-info .show-quality {
margin-right: 10px;
padding: 4px 4px 0px 6px;
border: solid 1px #808080;
line-height: 12px;
height: 21px;
}
.site-capitanelectrico .channel-info .show-description {
margin-top: 30px;
width: 70%;
}

/* ************************ */

.site-capitanelectrico .body {
/* padding: 20px 100px;   */
padding: 20px 0px;  
}

.site-capitanelectrico .body .show-info {
background: #1c1c1c;
padding: 40px;
display: flex;
justify-content: center;
}


.site-capitanelectrico .show-info .show-info-border {
border: solid 8px #ed3739;
padding: 20px;
max-width: 1300px;
}
.site-capitanelectrico .show-info .show-info-header {
display: flex;
flex-direction: row;
}

.site-capitanelectrico .show-info .album-image {
padding: 10px;
width: 320px;
height: 320px;
padding: 0px 20px 20px 0px;
}
.site-capitanelectrico .show-info .album-image img {
width: 100%;
height: 100%;
}
.site-capitanelectrico .show-info .album-info {  
text-transform: uppercase;  
}
.site-capitanelectrico .show-info .album-info .album-title {  
font-size: 26px;
color: white;
text-transform: uppercase;
font-weight: 600;
letter-spacing: 5px;
}
.site-capitanelectrico .show-info .album-info .album-subtitle {  
font-size: 18px;
line-height: 26px;
color: #eb3839;
text-transform: uppercase;
font-weight: 600;
letter-spacing: 5px;
padding-bottom: 5px;
}
.site-capitanelectrico .show-info .album-info .show-date {  
font-size: 14px;
line-height: 26px;
color: white;
font-weight: 600;
text-transform: uppercase;  
}
.site-capitanelectrico .show-info .album-info .show-date-alternate {  
font-size: 14px;
color: white;
text-transform: uppercase;
padding-bottom: 5px;
}
.site-capitanelectrico .show-info .album-info .show-venue {  
font-size: 10px;
color: white;
text-transform: uppercase;
border-bottom: solid 1px white;
padding-bottom: 20px;
letter-spacing: 3px;
}

.site-capitanelectrico .show-info .album-info .ticket-box {
display: flex;
flex-direction: row;
column-gap: 10px;
padding-top: 20px;
align-items: center;  
}
.site-capitanelectrico .show-info .show-play button {
background: #BFD96E;
border: none;
padding: 10px 40px;
border-radius: 10px;
color: #404040;
text-transform: uppercase;
font-size: 20px;
font-weight: 600;
margin: 0px;
letter-spacing: 2px;
}
.site-capitanelectrico .show-info .show-play button:hover {
background: #d2e98c;
}

.site-capitanelectrico .show-info .ticket-box .show-ticket-info {
font-size: 14px;
letter-spacing: 2px;
}

.site-capitanelectrico .show-info .ticket-info {
margin-top: 20px;
border-top: solid 1px white;
padding: 10px 0px;  

}
.site-capitanelectrico .show-info .ticket-info .ticket-info-layout {
display: flex;
justify-content: flex-start;
column-gap: 10px;
}

.site-capitanelectrico .show-info .ticket-info label {
font-size: 20px;
font-weight: bold;
text-transform: uppercase;
}
.site-capitanelectrico .show-info .ticket-info .ticket-prices {
/* width: 400px; */
}
.site-capitanelectrico .show-info .ticket-info ul {
width: 400px;
text-transform: uppercase;
font-size: 12px;
letter-spacing: 2px;
padding: 0px;    
}
.site-capitanelectrico .show-info .ticket-info ul li {
display: flex;
flex-direction: row;
justify-content: space-between;
padding-bottom: 10px;
margin: 0px;
/* font-weight: bold; */

}
.site-capitanelectrico .show-info .ticket-info .ticket-buttons {
text-align: right;
display: flex;
flex-direction: row;  
justify-content: flex-end;
}
.site-capitanelectrico .show-info .ticket-info .ticket-buttons .ticket {
display: flex;
flex-direction: row;
align-items: center;  
}
.site-capitanelectrico .show-info .ticket-info .ticket-buttons .hole-start {
width: 20px;
height: 20px;
border-radius: 10px;
background: #1c1c1c;
margin-right: -8px;
z-index: 10;
}
.site-capitanelectrico .show-info .ticket-info .ticket-buttons .hole-end {
width: 20px;
height: 20px;
border-radius: 10px;
background: #1c1c1c;
margin-left: -8px;
z-index: 10;
}

.site-capitanelectrico .show-info .ticket-info .ticket-buttons button {
background: #efcd14;
border: none;
padding: 10px 40px;
border-radius: 10px;
color: #404040;
text-transform: uppercase;
font-size: 16px;
font-weight: 600;
margin: 0px;
line-height: 16px;
min-width: 290px;
letter-spacing: 2px;
}
.site-capitanelectrico .show-info .ticket-info .ticket-buttons button:hover {
background: #edd346;
}


.site-capitanelectrico .show-info .ticket-info .ticket-buttons button span {
font-size: 8px;
/* font-weight: lighter; */
white-space: nowrap;
line-height: 8px;
}

.site-capitanelectrico .show-info .ticket-info .ticket-buttons button:disabled,
.site-capitanelectrico .show-info .ticket-info .ticket-buttons button[disabled] {
background: #949494;
}

.site-capitanelectrico .show-info .ticket-info .ticket-sales {
text-transform: uppercase;
font-size: 12px;
padding-left: 10px;
}
.site-capitanelectrico .show-info .ticket-info .ticket-sales .ticket-sales-info {
letter-spacing: 3px;
padding-bottom: 10px;
}
.site-capitanelectrico .show-info .ticket-info .ticket-sales .ticket-sales-place {
display: grid;
grid-template-columns: 50px auto;
column-gap: 10px;
}
.site-capitanelectrico .show-info .ticket-info .ticket-sales .ticket-sales-place .place-icon {

}
.site-capitanelectrico .show-info .ticket-info .ticket-sales .ticket-sales-place .place-icon img {
width: 50px;
height: 50px;
}
.site-capitanelectrico .show-info .ticket-info .ticket-sales .ticket-sales-place dl {
display: flex;
flex-direction: column;
justify-content: center;
height: 50px;
padding: 0px;
margin: 0px;
}
.site-capitanelectrico .show-info .ticket-info .ticket-sales .ticket-sales-place dt {
font-size: 14px;
letter-spacing: 3px;
}
.site-capitanelectrico .show-info .ticket-info .ticket-sales .ticket-sales-place dd {
font-size: 12px;
padding: 0px;
margin: 0px;
}

.site-capitanelectrico .show-info .quickbuy_link {
  text-transform: none;
}
.site-capitanelectrico .show-info .quickbuy_link a {
  display: grid;
  grid-template-columns: 50px auto;
  align-items: center;
  font-size: 1rem;
  text-decoration: none;
  border: solid 1px #117a8b;
  background: #17a2b8;
  padding: 10px;
  border-radius: 5px;
}
.site-capitanelectrico .show-info .quickbuy_link a:hover {
  background: #117a8b;
}
.site-capitanelectrico .show-info .quickbuy_link a svg {
  font-size: 30px;
}


/* ************************ */

.site-capitanelectrico .song-container {
border-top: solid 1px #4B4B4B;
margin-top: 40px;
padding: 40px 40px 0px 40px;

display: grid;
grid-template-columns: 380px 300px auto;
column-gap: 30px;
}

.site-capitanelectrico .song-container .song-cover {
width: 380px;
height: 380px;
}
.site-capitanelectrico .song-container .song-cover img {
width: 100%;
height: 100%;
}
.site-capitanelectrico .song-container .song-info {

}
.site-capitanelectrico .song-container .song-info h1,
.site-capitanelectrico .song-container .song-info2 h1 {
font-size: 1.3rem;
font-weight: bold;
padding: 0;
margin: 0;
}
.site-capitanelectrico .song-container .song-info label,
.site-capitanelectrico .song-container .song-info label {
font-size: 0.8rem;
text-transform: uppercase;

}
.site-capitanelectrico .song-container .song-info dl,
.site-capitanelectrico .song-container .song-info2 dl {
padding-top: 20px;
display: grid;
grid-template-columns: 150px auto;
font-size: 0.7rem;
}
.site-capitanelectrico .song-container .song-info dl dt,
.site-capitanelectrico .song-container .song-info2 dl dt {
/* float: left; */
margin: 0;
}
.site-capitanelectrico .song-container .song-info dl dd,
.site-capitanelectrico .song-container .song-info2 dl dd {
/* float: left; */
margin: 0;
}

.site-capitanelectrico .song-video {
justify-content: center;
text-align: center;
padding: 20px 0px;
}
.site-capitanelectrico .song-video h1 {  
justify-content: center;
font-size: 1.2rem;
font-weight: bold;
}
.site-capitanelectrico .song-video .song-video-container {
display: flex;
justify-content: center;
padding: 20px 0px;
}
.site-capitanelectrico .song-video .song-video-container .youtube {
width: 800px;
height: 450px;
}
.site-capitanelectrico .song-video .song-video-container .placeholder {
width: 80%;
}
.site-capitanelectrico .song-video .song-video-container .vimeo {
width: 800px;
height: 450px;
justify-content: center;
align-items: center;
}


.site-capitanelectrico .song-gallery {  
justify-content: center;
text-align: center;
padding: 20px 0px;
}
.site-capitanelectrico .song-gallery h1 {
justify-content: center;
font-size: 1.2rem;
font-weight: bold;
}
.site-capitanelectrico .song-gallery .gallery-container {
display: flex;
justify-content: center;
/* width: 80%; */
}
.site-capitanelectrico .song-gallery .gallery-container .carousel {
width: 800px;
}

.site-capitanelectrico .social-links {
display: flex;
flex-direction: row;
flex-wrap: wrap;
gap: 10px;
padding: 5px 0px;
}
.site-capitanelectrico .social-links a {
text-decoration: none;
white-space: nowrap;
}
.site-capitanelectrico .social-links a:hover {
text-decoration: underline;
}
.site-capitanelectrico .custom-icon {
width: 30px;
height: 30px;
display: inline-block;

}
.site-capitanelectrico .custom-icon.deezer {
background-image: url('https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/jam_deezer-circle.png')
}


.site-capitanelectrico .footer {
background: #414141;
padding: 30px;
margin: 50px 100px;    
}
.site-capitanelectrico .footer h2 {
font-size: 1.3rem;
}
.site-capitanelectrico .footer .content {
display: grid;
grid-template-columns: auto 200px;
text-align: justify;
}
.site-capitanelectrico .footer .content .logos {
padding-left: 20px;
}
.site-capitanelectrico .footer .logos {
display: flex;
flex-direction: row;
justify-content: center;
align-items: flex-start;
}
.site-capitanelectrico .footer .logos img {
/* height: 150px; */
}

@media (max-width: 768px) {

.site-capitanelectrico {
  margin-top: 0px !important;
}
.site-capitanelectrico .header .background {
  display: none;
}
.site-capitanelectrico .header .background-mobile {
  display: block;
}
.site-capitanelectrico .channel-info {
  margin-top: -150px;
  padding: 0px 20px;
}

.site-capitanelectrico .channel-info .show-description {
  margin-top: 30px;
  width: 100%;
}

/* ************************ */

.site-capitanelectrico .body {
  padding: 30px 0px;
}
.site-capitanelectrico .body .show-info {
  padding: 40px 20px;
}


.site-capitanelectrico .show-info .show-info-border {
  padding: 10px;
  max-width: 100%;
  border-width: 4px;
}
.site-capitanelectrico .show-info .show-info-header {
  display: flex;
  flex-direction: column;    
}
.site-capitanelectrico .show-info .show-description {
  padding-top: 20px;
  text-align: justify;
}
.site-capitanelectrico .show-info .album-image {
  padding: 0px 0px 0px 0px;
  margin-bottom: 20px;
}

.site-capitanelectrico .show-info .album-info .album-title {
  letter-spacing: 0px;
}
.site-capitanelectrico .show-info .album-info .album-subtitle {
  font-size: 16px;
}
.site-capitanelectrico .show-info .album-info .ticket-box {
  flex-direction: column;
}
.site-capitanelectrico .show-info .ticket-info .ticket-info-layout {
  flex-direction: column;
}
.site-capitanelectrico .show-info .ticket-info ul {
  width: 100%;
}
.site-capitanelectrico .show-info .ticket-info .ticket-buttons {
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
}

.site-capitanelectrico .song-container {
  display: grid;
  grid-template-columns: 300px;
  row-gap: 30px;

}
.site-capitanelectrico .song-container .song-cover {
  width: 300px;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.site-capitanelectrico .song-gallery .carousel {
  width: 300px;
  /* overflow-y: none; */
  /* overflow-x: auto; */
}

.site-capitanelectrico .song-video .song-video-container .youtube {
  width: 300px;
  height: 168px;
}
.site-capitanelectrico .song-video .song-video-container .vimeo {
  width: 300px;
  height: 168px;
}

.site-capitanelectrico .song-gallery .gallery-container .carousel {
  width: 100%;
}

.site-capitanelectrico .footer {
  padding: 30px;
  margin: 0px 20px;
}
.site-capitanelectrico .footer .content {
  display: inline-block;
  margin: 0px;    
}
.site-capitanelectrico .footer .content .logos {
  padding: 0px;
}

}
