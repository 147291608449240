
.site-jandir { 
    color: white;
    font-family: 'Montserrat' !important;
    padding-left: 0px;
    padding-right: 0px;
    /* margin-top: 105px !important; */
    background-color: black;    
}

.site-jandir .header .background {
  width: 100%;
  height: 56.25vw;  
  background-position: center center;
  background-size: cover;  
  /* background-image: url("http://assets.viveloonline.com/shows/5eed57a46ba924af375b7178/juevesdetrova-20200626-portada.png"); */
  z-index: -1;
  /* position: absolute; */
  top:0;
  left:0;
}

.site-jandir .show-info {
  margin-top: -110px;
  padding: 0px 100px;
}

.site-jandir .show-info .show-title {
  font-weight: bold
}

.site-jandir .show-info .show-badges {
  font-size: 10px;
  display: flex;
  justify-content: flex-start;
  line-height: 21px;  
}
.site-jandir .show-info .show-year {
  margin-right: 10px;
  line-height: 21px;
  height: 21px;
}
.site-jandir .show-info .show-rating {
  margin-right: 10px;
  padding: 4px 4px 0px 6px;
  border: solid 1px #4B4B4B;
  line-height: 12px;
  height: 21px;
}
.site-jandir .show-info .show-quality {
  margin-right: 10px;
  padding: 4px 4px 0px 6px;
  border: solid 1px #4B4B4B;
  line-height: 12px;
  height: 21px;
}
.site-jandir .show-info .show-description {
  margin-top: 30px;
  width: 70%;
}

.site-jandir .body {
  padding: 20px 100px;  
}


.site-jandir .song-container {
  border-top: solid 1px #4B4B4B;
  margin-top: 40px;
  padding-top: 40px;
  display: grid;
  grid-template-columns: 380px auto 100px;
  column-gap: 30px;
}

.site-jandir .song-container .song-cover {
  width: 380px;
}
.site-jandir .song-container .song-cover img {
  width: 100%;
  height: 100%;
}
.site-jandir .song-container .song-info {
  
}
.site-jandir .song-container .song-info h1 {
  font-size: 1.3rem;
  font-weight: bold;
  padding: 0;
  margin: 0;
}
.site-jandir .song-container .song-info label {
  font-size: 0.8rem;
  text-transform: uppercase;
  
}
.site-jandir .song-container .song-info dl {
  padding-top: 20px;
  display: grid;
  grid-template-columns: 150px auto;
  font-size: 0.7rem;
}
.site-jandir .song-container .song-info dl dt {
  /* float: left; */
  margin: 0;
}
.site-jandir .song-container .song-info dl dd {
  /* float: left; */
  margin: 0;
}

.site-jandir .song-video {
  justify-content: center;
  text-align: center;
  padding: 20px 0px;
}
.site-jandir .song-video h1 {  
  justify-content: center;
  font-size: 1.2rem;
  font-weight: bold;
}
.site-jandir .song-video .song-video-container {
  display: flex;
  justify-content: center;
  padding: 20px 0px;
}
.site-jandir .song-video .song-video-container .youtube {
  width: 800px;
  height: 450px;
}
.site-jandir .song-video .song-video-container .placeholder {
  width: 80%;
}
.site-jandir .song-video .song-video-container .vimeo {
  width: 800px;
  height: 450px;
  justify-content: center;
  align-items: center;
}


.site-jandir .song-gallery {  
  justify-content: center;
  text-align: center;
  padding: 20px 0px;
}
.site-jandir .song-gallery h1 {
  justify-content: center;
  font-size: 1.2rem;
  font-weight: bold;
}
.site-jandir .song-gallery .gallery-container {
  display: flex;
  justify-content: center;
  /* width: 80%; */
}
.site-jandir .song-gallery .gallery-container .carousel {
  width: 800px;
}

.site-jandir .social-links {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  padding: 5px 0px;
}
.site-jandir .social-links a {
  text-decoration: none;
  white-space: nowrap;
}
.site-jandir .social-links a:hover {
  text-decoration: underline;
}
.site-jandir .custom-icon {
  width: 30px;
  height: 30px;
  display: inline-block;
  
}
.site-jandir .custom-icon.deezer {
  background-image: url('https://assets.viveloonline.com/shows/614e1d380ffb10805d96f43f/jam_deezer-circle.png')
}


.site-jandir .footer {
  background: #414141;
  padding: 30px;
  margin: 50px 100px;    
}
.site-jandir .footer h2 {
  font-size: 1.3rem;
}
.site-jandir .footer .content {
  display: grid;
  grid-template-columns: auto 200px;
  text-align: justify;
}
.site-jandir .footer .content .logos {
  padding-left: 20px;
}
.site-jandir .footer .logos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
.site-jandir .footer .logos img {
  /* height: 150px; */
}

@media (max-width: 768px) {

  .site-jandir {
    margin-top: 50px !important;
  }
  .site-jandir .show-info {
    margin-top: -30px;
    padding: 0px 20px;
  }
  .site-jandir .show-info .show-description {
    margin-top: 30px;
    width: 100%;
  }
  .site-jandir .body {
    padding: 10px 20px;
  }


  .site-jandir .song-container {
    display: grid;
    grid-template-columns: 300px;
    row-gap: 30px;

  }
  .site-jandir .song-container .song-cover {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .site-jandir .song-gallery .carousel {
    width: 300px;
    /* overflow-y: none; */
    /* overflow-x: auto; */
  }

  .site-jandir .song-video .song-video-container .youtube {
    width: 300px;
    height: 168px;
  }
  .site-jandir .song-video .song-video-container .vimeo {
    width: 300px;
    height: 168px;
  }

  .site-jandir .song-gallery .gallery-container .carousel {
    width: 100%;
  }

  .site-jandir .footer {
    padding: 30px;
    margin: 0px 20px;
  }
  .site-jandir .footer .content {
    display: inline-block;
    margin: 0px;    
  }
  .site-jandir .footer .content .logos {
    padding: 0px;
  }

}
