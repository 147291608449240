
.quicklink {

}
.quicklink .show-header {
    position: fixed;
    z-index: 100;
    width: 100%;
    top: 0;
    left: 0;
}


.quicklink .show-form {
    padding: 50px 20px 100px 20px;
}

.quicklink .show-title.primary {
    font-size: 20px;
    font-weight: bold;
}

.quicklink .show-date {
    font-weight: normal;
}

.quicklink section#dates {
    text-transform: uppercase;
    font-size: 12px;
    margin-top: 10px;
}
.quicklink section#dates label {
    margin-bottom: 3px;
}

.quicklink section#tickets {
    text-transform: uppercase;
    font-size: 12px;
    margin-top: 20px;
}
.quicklink section#tickets label {
    margin-bottom: 3px;
}

.quicklink .tickets-total {
    font-weight: bold;
    font-size: 20px; 
}

.quicklink h2 {
    font-size: 1.2rem;
}

.quicklink .deposit-info {
    padding: 10px;
    border: solid 1px #333;
    border-radius: 5px;
    font-size: 14px;
    text-align: left;
    margin-bottom: 5px;
    background: #fdecb8;
    color: black;
}

.quicklink .content {
    margin-top: 40px;
    padding-bottom: 80px;
}

.quicklink .loginBox {
    margin: 20px 10px 20px 10px;
    padding: 15px;
    border: solid 1px white;
    border-radius: 5px;
}
.quicklink .loginBox button {
    margin-top: 10px;
}

@media (max-width: 768px) {
    .quicklink .social-icons {
        width: 100%;
        left: 0px;
        height: 38px;
        background: black;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        z-index: 0;
    }
}
