/* #helpme {
  display: inline-block;
  position: fixed;
  top: 9px;
  right: 130px;
  background: url(/img/messenger.png) no-repeat;
  width: 70px;
  height: 30px;
  background-size: contain;
  color: white;
  background-position: right;
  line-height: 30px;
}
#helpme:hover {
  width: 70px;
  height: 32px;
} */


.show-container {
  /* display: flex; */
  flex-direction: column;
  min-height: 100vh;
  height: 100vh;
  
  margin:0;
}
.show-container .small-logo {
  width: 84px;
  height: 30px;
  /* line-height: 60px; */
}
.show-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;  
  align-items: center;
  padding: 10px 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.show-content {
  /* flex-grow: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-between; */
  
}


.show-player {
  /* background: #1c1c1c; */
  background: black;
  width: 100%;
  /* position: relative; */
  padding: 0px;  
}
.show-interactions {
  /* width: 500px; */
  width: 100%;
  min-height: 450px;
  /* height: 100%; */
  /* /background: black; */
  background: #1c1c1c;
  /* background: red; */
}
.show-footer {  
}
.show-footer .social-footer {
  margin: 0;
  padding: 5px;
}
.show-header .social-icons {
  color: white;
}
.show-header .social-icons a {
  color: white;
}

@media (min-width: 900px) {
  .show-content {    
    display: grid;
    grid-template-columns: auto 350px;
  }
  .show-content.vod {
    display: block;
  }
  .show-interactions {

    /* min-width: 500px; */
  }
}

/*.video-player {
  background-color: black;
  background-image: url('../img/show1.jpg');    
  width: 900px;
  height: 506px;
  margin: 5px;
}*/
.video-player {
  background-color: black;  
  background-image: url('/img/show1.jpg');
  background-size: cover;
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}
.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.react-player iframe {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
}

.show-player button {
  background: transparent;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  outline: none;
}
.show-player button:not(:disabled):not(.disabled).active, .show-player button:not(:disabled):not(.disabled):active,
.show-player button:hover, .show-player button:active, .show-player button:focus {
  /*background: #121212;*/
  background: transparent;
  background-color: transparent !important;
  color: #fa5504;  
  border: none;
  border-color: transparent !important;
  border-radius: 0px;
  box-shadow: none;
  outline: none;
}

.show-player .chat-box .chata, .show-player .chat-box .chat .show-messages {
  height: 500px;
}

.show-player .title {
  display: none;
}

.show-ended {
  /*width: 1100px;*/
  /* width: 100%;
  height: 618px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.show-ended .content {
  text-align: center;
  padding: 0px 10px;
}
.show-ended-isotipo {
  width: 100px;
  height: 100px;
}
.show-ended-title {
  font-size: 1.4rem
}
.show-ended-comment {
  font-family: 'Montserrat';  
}
.show-ended-comment input[type=text] {
  text-align: center;
  text-transform: uppercase;
}
.show-ended-error {  
  color: red;
  background: white;
  border-radius: 30px;
}

.chat-box {
  /* background: #1c1c1c; */
  height: 100%;
  padding: 5px;
}

.show-container .show-header .small-logo {
  display: inline-block;
  flex-basis: unset;
  width: unset;
}

.show-footer .help-box {
  
}
.show-footer .help-box h3 {
  padding: 20px;
  margin: 0px;
  background: black;
}
.show-footer .faq-box {
  display: grid;  
  font-family: Montserrat;
  font-size: 0.9rem;
  padding: 20px;
  background: rgb(0,0,0,0.6);
}

.show-playlist {
  padding: 40px 40px 40px 40px;  
  background: #191919;
}
.show-playlist .show-item {
  /* margin: 20px; */
  padding: 40px;
  display: grid;
  grid-template-columns: 320px auto;
  grid-template-rows: 1.5rem auto;
  border-bottom: solid 1px #333333;
}
.show-playlist .show-item.active {
  background: #333333;
}
.show-playlist .show-item .show-item-image {
  grid-row: 1 / span 2;
  width: 300px;
  height: 170px;
  margin-right: 20px;
  position: relative;
  /* margin-bottom: 20px; */
}
.show-playlist .show-item .show-item-image img {
  width: 300px;
  height: 170px;
}
.show-playlist .show-item .show-item-image .play-overlay {
  width: 100%;
  height: 100%;
  color: white;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-content: center;
}
.show-playlist .show-item .show-item-image .play-overlay a {
  width: 100%;
  height: 100%;
  font-size: 5rem;
  color: rgb(255,255,255,0);
  background: rgb(0,0,0,0);
  cursor: pointer;
}
.show-playlist .show-item .show-item-image .play-overlay a:hover {
  width: 100%;
  height: 100%;  
  /* color: rgb(0,0,0,0.7);
  background: rgb(255,255,255,0.4); */
  color: rgb(255,255,255,1);
  background: rgb(0,0,0,0.7);
}
.show-playlist .show-item .show-item-image .live-overlay {
  /*width: 100%;
  height: 100%;*/
  color: white;
  position: absolute;
  top: 0px;
  right: 0px;
  text-align: right;  
}
.show-playlist .show-item .show-item-image .live-overlay div {
  z-index: 101;
  font-size: 0.8rem;
  margin-right: 10px;
  margin-top: 6px;  
  padding: 2px 5px;
  border-radius: 3px;
  background: rgb(0,0,0,0.5);
}
.show-playlist .show-item .show-item-image .soon-overlay {
  width: 100%;
  height: 100%;
  color: white;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-content: center;
  background: rgb(0,0,0,0.7);
}
.show-playlist .show-item .show-item-content {
  padding-left: 20px;
}
.show-playlist .show-item .show-item-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.show-playlist .show-item .show-item-title {
  font-weight: bold;
  margin-bottom: 5px;
}
.show-playlist .show-item .show-item-date {
  font-style: italic;
  font-size: 0.9rem;
}
.show-playlist .show-item .show-item-description {

}


@media (min-width: 768px) {  
  .show-player .chat-box {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 500px;
    height: 300px;
    z-index: 900;
  }

  .show-footer .faq-box {
    grid-template-columns: 50% auto;
  }

  /* .show-ended {
    width: 920px;
    height: 517px;
  }   */
}

@media (max-width: 768px) {
  .show-container {
        
  }  
  .show-container .show-header {
    /* height: 38px; */
  }
  .show-container .show-header .show-title {
    /* font-size: 1.2rem; */
    /* padding-top: 15px; */
    /* display: none; */
  }
  .show-container .small-logo {
    width: 70px !important;
    height: 25px !important;
    /* line-height: 60px; */
  }
  .show-content {
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
  }
  .show-interactions {
    width: 100%;
    height: 100%;    
  }  

  .show-interactions .chat-box {    
    width: 100%    
  }
  
  /* .show-player .chat-box .chata, .show-player .chat-box .chat .show-messages {
    height: 300px;
  } */

  .show-interactions .chat-box .chat .show-messages {
    /* flex-direction: column-reverse; */
    padding-top: 10px;
  }

  .show-ended {
    width: 100%;
    /* height: 400px; */
  }

  .show-footer .faq-box {
    /* grid-template-rows: 50% auto; */
  }

  .show-playlist {
    padding: 20px 10px 20px 10px;  
    background: #191919;
  }
  .show-playlist .show-item {    
    padding: 20px 10px;    
    grid-template-columns: 160px auto;
    grid-template-rows: auto;
  }
  .show-playlist .show-item.active {
    background: #333333;
  }
  .show-playlist .show-item .show-item-image {
    grid-row: 1 / 1;
    width: 150px;
    height: 85px;
    margin-right: 10px;    
    position: relative;
    /* margin-bottom: 20px; */
  }
  .show-playlist .show-item .show-item-image img {
    width: 150px;
    height: 85px;
  }
  .show-playlist .show-item .show-item-image .play-overlay a {
    font-size: 2.5rem;
    color: rgb(255,255,255,1);
    background: rgb(0,0,0,0.4);
  }
  .show-playlist .show-item .show-item-image .live-overlay div {
    margin-right: 0px;
    margin-top: 0px;  
  }
  .show-playlist .show-item .show-item-info {
    display: block;
  }
  .show-playlist .show-item .show-item-description {
    grid-column: 1 / span 2;
    padding-top: 10px;
  }
}

@media (max-width: 768px) {
  .show-ended {
    width: 100%;
    /* height: 281px; */
  }

  #helpme {
    width: 30px !important;
  }

  .chat-mobile {
    display: none;
  }
}
/*
@media (max-width: 667px) {
  .Home .soon {    
    width: 510px;
    min-height: 178px;
  }   
}
*/
@media (max-width: 576px) {
  .show-header .social-icons {
    position: fixed;
    bottom: 0px;
  }

}

@media (max-width: 375px) {
  .show-ended {
    width: 100%;
    /* height: 169px; */
  }  
  .show-ended-isotipo {
    width: 50px;
    height: 50px;
  }
  .show-ended-title {
    font-size: 1rem;
  }
  .show-ended-comment {
    font-size: 0.8rem;
  }
  .show-ended-error {
    font-size: 0.8rem;
    color: red;
  }
}
/*
@media (max-width: 320px) {
  .Home .soon {    
    width: 250px;
    min-height: 87px;
    margin-bottom: 20px;
  }    
} */

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
  /* For portrait layouts only */
  .show-content {
    /* flex-direction: column;
    justify-content: stretch;
    align-items: stretch; */
  }
  /* .show-interactions {
    width: 100%;
    height: 447px;
  }  

  .show-interactions .chat-box {    
    width: 100%    
  } */
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
  /* For landscape layouts only */
  /* .show-content {
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
  }
  .show-interactions {
    width: 100%;
    height: 100%;    
  }  

  .show-interactions .chat-box {    
    width: 100%    
  } */  
  
  .show-messages {
    font-size: 0.7rem;
  }
}